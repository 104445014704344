

.bg-main-intro{
  background-color: #f8f9fe;
  margin-top: 200px;
}

.main-intro{
  text-align: center;
  padding: 100px 0px;
  word-break: keep-all;
}


.main-intro .main-intro-top { 
  font-family: 'NanumSquareAcr';
  font-size: 2.4rem;
  line-height: 2.5;
  color: #2c7eea;

}
.main-intro .main-intro-title { 
  font-family: 'ONE-Mobile-Title';
  font-size: 4.8rem;
  line-height: 1.25;
}
.main-intro .main-intro-sub { 
  font-family: 'NanumSquareAcr';
  font-size: 2.4rem;
  line-height: 1.58;
  color: #777;
}

.main-intro .mt50{
  margin-top: 50px;
}

.main-intro .mt70{
  margin-top: 70px;
}

.main-intro .main-intro-button{
  cursor: pointer;
  margin-top: 80px;
  width: 257px;
  height: 67px;
  border-radius: 33.5px;
  background-color: #2c7eea;
  font-family: 'NanumSquareBold';
  font-size: 18px;
  line-height: 2.11;
  color: #fff;
}

@media screen and (max-width:768px) {
  
.bg-main-intro{
  margin-top: 0px;
}

.main-intro{
  text-align: center;
  padding: 26.041vw 0vw 0vw 0vw;
  word-break: keep-all;
}


.main-intro .main-intro-top { 
  font-family: 'NanumSquareAcr';
  font-size: 3.906vw;
  line-height: 2.5;
  color: #2c7eea;
}
.main-intro .main-intro-title { 
  font-family: 'ONE-Mobile-Title';
  font-size: 6.25vw;
  line-height: 1.25;
}
.main-intro .main-intro-sub { 
  font-family: 'NanumSquareAcr';
  font-size: 3.645vw;
  line-height: 1.58;
  color: #777;
  
  word-break: keep-all;
}
.main-intro .mt50{
  margin-top: 5.208vw;
}

.main-intro .mt70{
  margin-top: 7.812vw;
}

.main-intro .main-intro-button{
  margin-top: 5.859vw;
  margin-bottom: 26.041vw;
  width: 37.369vw;
  height: 9.765vw;
  border-radius: 33.5px;
  background-color: #2c7eea;
  font-family: 'NanumSquareBold';
  font-size: 3.645vw;
  line-height: 2.11;
  color: #fff;
}
}
