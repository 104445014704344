.board-layout {
  width: 100%;
}

.board-list .select-box{
  width: 20vw;
}

.board-list .select-box > div{
  margin: 10px 0;
}

.board-list > .board-info > .board-total{
  font-family: 'NanumSquareAcr';
  font-size: 16px;
  color: #1d99df;
}

.board-list > .board-info > .board-total > span{
  color: #444444;
}

.board-list > .boardList > li .board-subj,.board-comment,.comment-complete,.comment-ready,.board-num-title,.board-num,.board-writer,.board-date-title,.board-date,.board-count-title,.board-count{
  font-family: 'NanumSquareAcr';
  font-size: 15px;
  color: #222;
}

.board-list > .boardList > li .board-subj .writer{
  font-family: 'NanumSquareAcr';
  font-size: 14px;
}

.board-list > .boardList > li .board-subj .writer img{
  width: 16px;
  margin-right: 10px;
}


.board-list .board-info{
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin: 0px 0px 37px 0px;
}

.board-list .board-info .board-total{
  text-align: left;
}

.board-list .board-info .board-write-btn {
  text-align: right;
  width: 30%;
}

.board-list ul .head{
  background-color: #f7f7f7;
  border-top: #222;
}

.board-list ul li{
  min-height: 34px;
  display: flex;
  border-bottom: 1px solid #e5e5e5;
  padding: 10px 0px;
  text-align: center;
  align-items: center;
}

.board-list ul li a{
  display: flex;
  width: 100%;
  align-items: center;
}

.board-list ul li:first-child{
  border-top: 1px solid #222;
}

.board-list ul li .board-num-title{
  width: 8%;
}

.board-list ul li .board-num{
  font-family: 'Lato';
  width: 8%;
}

.board-list ul li .board-category{
  width: 20%;
}

.board-list ul li .board-subj{
  width: 52%;
}

.board-list ul li .board-comment{
  align-items: center;
  width: 10%;
}

.board-list ul li .board-comment .comment-complete img{
  width: 24px;
}

.board-list ul li .board-comment .comment-ready{
}

.board-list ul li .board-writer{
  width: 10%;
}

.board-list ul li .board-date-title{
  width: 15%;
}

.board-list ul li .board-date{
  font-family: 'Lato';
  width: 15%;
}

.board-list ul li .board-count-title {
  width: 5%;
}

.board-list ul li .board-count {
  font-family: 'Lato';
  width: 5%;
}

.board-list ul li a .board-subj{
  text-align: left;
}

ul.paging { 
  width:100%;
  height: 16px;
	text-align:center;
	margin:50px 0px 200px 0px;
}

ul.paging li { 
  width: auto;
	display:inline-block;
	margin:0px 10px;
}

ul.paging li a {
  font-family: 'Lato';
  font-size: 16px;
	color:#777;
}

ul.paging .on a {
  font-weight: bold;
  color: #222;
  text-decoration: underline;
}

ul .paging li img{
	width:100%;
}

.detail-title-box {
  display: flex;
  justify-content: space-between;
  margin: 40px 20px;
}

.detail-title-box .detail-writer{
  font-size: 26px;
  font-weight: 400;
}

.detail-writer li{
  font-size: 14px;
  display: inline-block;
  padding: 0px 10px;
}

.detail-writer li:not(:last-child){
  border-right: 1px solid #e9e9e9;
}

.detail-content-box {
  border: 1px solid #e5e5e5;
}

.detail-content-box .board-content{
  height: auto;
  padding: 30px 20px 50px 20px;
}

.detail-content-box .board-content p{
  font-size: 18px;
}

.detail-content-box .board-file{
  height: auto;
}

.detail-content-box .board-comment{
  min-height: 120px;
}

.detail-content-box .board-comment .top{
  display: flex;
  justify-content: space-between;
}

.detail-content-box .board-comment .title{
  font-size: 15px;
  font-weight: 600;
  padding: 20px;
}

.detail-content-box .board-comment .file{
  font-size: 15px;
  font-weight: 400;
  padding: 20px;
}

.detail-content-box .board-comment .file img{
  margin-right: 10px;
}

.detail-content-box .board-comment .file button{
  font-size: 15px;
  margin-left: 20px;
}

.detail-content-box .board-comment .name{
  font-size: 15px;
  font-weight: 600;
  padding: 30px 0px 0px 20px;
}

.detail-content-box .board-comment .func{
  font-size: 15px;
  font-weight: 600;
  padding: 30px 30px 0px 20px;
}

.detail-content-box .board-comment .func button{
  font-size: 15px;
  font-weight: 400;
  margin-left: 10px;
}



.detail-content-box .board-comment .name span{
  margin-right: 10px;
}

.detail-content-box .board-comment .rt1{
  border: 1px solid #e5e5e5;
}

.detail-content-box .board-comment .rt2{
  border: 2px solid #e5e5e5;
}

.detail-content-box .board-comment .comment-write{
  padding: 30px 20px 50px 20px;}

.detail-content-box .board-comment .comment-write p{
  font-size: 17px;
}

.detail-content-box .board-comment .comment-write .comment-btn-area{
  margin-top: 10px;
  display: flex;
  justify-content: end;
}

.detail-content-box .board-comment .comment-write .editor{
  height: 250px;
  min-height: 250px;
}

.detail-content-box .board-btn{
  padding: 30px;
  border-top: 1px solid #e5e5e5;
  background: #f7f7f7;
}

.detail-content-box .board-btn .btn-box{
  display: flex;
  justify-content: flex-end;
}

.detail-content-box .board-btn .btn-box button{
  display: flex;
  font-size: 16px;
  padding: 15px 30px;
  background: white;
  border-radius: 50px;
  border: 1px solid #e5e5e5;
  margin: 0px 10px;
}

.detail-content-box .board-btn .btn-box button:hover {
  background:#EEEEEE;
  cursor: pointer;
}

.detail-content-box .board-btn .btn-box button svg{
  width: 16px;
  height: 14px;
  margin: 3px 3px 0px 3px;
}

.input-hook {
  border: 1px solid #eee;
  border-radius: 0.2rem;
  padding: 10px;
  width: 100%;
}

.input-hook.error {
  outline: 1px solid red!important;
  outline-offset: -0.2rem!important;
}

.attached-btn {
  width: 100%;
}

.board-cont-btn li{ 
  display: inline-block;
}

.board-list .boardList .pc-board-li {
  display: block;
}
.board-list .boardList .mobile-board-li {
  display: none;
}

.board-write{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.board-write .select-box{
  width: 20vw;
}

.board-write .select-box > div{
  margin: 10px 0;
}

.board-write .input-area {
  width: 100%;
} 

.board-write .input-area .input-unit {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.board-write .input-area .input-unit .input-hook {
  width: calc(100% - 30px);
  height: 42px;
  margin: 10px 0px 0px 0px;
  padding: 0px 0px 0px 25px;
}

.board-write .input-area .input-unit input {
  display: block;
  font-size: 16px;
  margin: 20px 0px 20px 0px;
  border: solid 1px #e5e5e5;
  border-radius: 10px;
  background-color: #fff;
}

.board-write .input-area .input-unit #attached_file{
  padding: 15px 0 0 20px;
}

.board-write .input-area .input-unit input::placeholder {
  color: #999;
  /* font-style: 'NanumSquareR'; */
}

.board-write .input-area .input-unit .error-message{
  color: red;
  font-size: 12px;
  text-align: center;
  margin-left: 10px;
}


.board-write .quill {
  margin-top: 20px;
  margin-bottom: 50px;
  height: 500px;
  font-size: 35px;
}

.ql-container .ql-editor {
  box-sizing: border-box;
  /* font-family: "NanumSquareR"; */
  font-size: 16px;
  color: #565759;
  margin: 0px;
  position: relative;
}

.ql-container p {
  box-sizing: border-box;
  font-family: "NanumSquareR";
  font-size: 16px;
  color: #565759;
  margin: 0px;
  position: relative;
}

.ql-toolbar.ql-snow {
  border: 1px solid #eee !important;
  border-radius: 10px 10px 0 0 ;
}

.ql-container.ql-snow {
  border: 1px solid #eee !important;
  border-radius: 0 0 10px 10px ;
}

.board-write .board-btn-area {
  width: 100%;
  margin: 90px 0;
  display: flex;
  justify-content: end;
}

.write-btn {
  cursor: pointer;
  font-size: 0.8333vw;
  background: #20BAEE;
  color: white;
  padding: 0.5208vw 1.0416vw;
  border-radius: 0.5208vw;
  border: 1px solid #20BAEE;
  margin-right: 0.5208vw;
}

.cancel-btn {
  background: white;
  color: #20BAEE;
  padding: 0.5208vw 1.0416vw;
  border-radius: 0.5208vw;
  border: 1px solid #20BAEE;
}





















@media screen and (max-width: 768px) {

  .board-list .select-box{
    width: 92vw;
  }
  
  .board-list .boardList .pc-board-li {
    display: none;
  }
  .board-list .boardList .mobile-board-li {
    display: block;
  }

  .board-list .board-info{
    margin: 0px 0px 11.1979vw 0px;
  }

  .board-list > .board-info > .board-total {
    font-size: 3.125vw;
}

  .board-list ul li{
    display: block;
    text-align: left;
  }

  .board-list ul li a {
    display: inline;
  }


  .board-list ul li p{
    font-size: 3.125vw;
  }
 
  .board-list ul .head .board-subj{
    font-size: 2.604vw;
  }
  
  .board-list ul li .board-num-title{
    display: none;
  }

  .board-list ul li .board-date{
    display: none;
  }

  .board-list ul li:first-child .board-writer button{
    display: block;
  }

  .board-list ul li:first-child .board-date button{
    display: none;
  }

  .board-list ul li .board-num{
    display: none;
  }

  .board-list ul li .board-subj{
    width: 100%;
    text-align: center;
  }

  .board-list ul li .board-comment{
    display: none;
  }

  .board-list ul li .board-writer{
    display: none;
  }

  .board-list ul li .board-count{
    display: none;
  }

  .detail-content-box .board-btn {
    padding: 20px;
  }


  .detail-content-box .board-btn .btn-box button {
    width: 100px;
    height: 30px;
    padding: 0px;
  }

  /* 모바일 글 리스트 */
  .board-list .boardList .mobile-board-sub-box p{
  }

  /* 모바일 글 리스트 */
  .board-list .boardList .mobile-board-sub-box {
    margin-top: 3px;
    display: flex;
    align-items: center;
  }

  .board-list .boardList .mobile-board-sub-box .mobile-board-writer{
    font-size: 1.822916vw;
    margin-right: 2.60416vw;
  }

  .board-list .boardList .mobile-board-sub-box .mobile-board-flex {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2.60416vw;
  }

  .board-list .boardList .mobile-board-sub-box .mobile-board-flex p{
    font-size: 1.822916vw;
  }

  .mobile-board-flex img{
    width: 2.60416vw;
    height: 2.60416vw;
    margin-right: 0.2604vw;
  }


  ul.paging { 
    width: 100%;
    height: 3.515625vw;
    margin:10.4166vw 0px 19.53125vw 0px;
  }

  ul.paging li { 
    margin:0px 2.62760vw;
  }
  
  
  ul.paging li a {
    font-size: 3.3854vw;
  }
  
  .detail-title-box{
    margin: 40px 0px;
  }

  .detail-title-box .detail-writer{
    display: flex;
  }

  .detail-title-box .detail-writer li {
    display: none;
  }

  .detail-content-box .board-content {
    padding: 1.302vw;
  }

  .detail-content-box .board-content p{
    font-size: 12px;
    line-height: 2;
  }

  .detail-content-box .board-comment .title{
    font-size: 11px;
    font-weight: 600;
    padding: 1.302vw;
  }
  
  .detail-content-box .board-comment .name{
    font-size: 11px;
    font-weight: 600;
    padding: 1.302vw;
  }
  
  .detail-content-box .board-comment .func{
    font-size: 11px;
    font-weight: 600;
    padding: 1.302vw;
  }
  


  .detail-content-box .board-comment .comment-write {
    padding: 1.302vw;
  }


  .detail-content-box .board-comment .comment-write p{
    font-size: 12px;
    line-height: 2;
  }
  
  
  .detail-content-box .board-btn .btn-box button{
    text-align: center;
    font-size: 11px;
    justify-content: center;
    align-items: center;
  }

  .detail-content-box .board-btn .btn-box button svg{
    width: 12px;
    height: 12px;
    margin: 0px 3px 0px 3px;
  }

  .board-write .select-box{
    width: 92vw;
  }

  .board-write .input-area .input-unit .input-hook {
    width: calc(100% - 10px);
  }

  .board-write .input-area .input-unit .input-hook {
    padding: 0px 0px 0px 10px;
  }
  
  .board-write .input-area .input-unit #attached_file{
    margin-top: 30px;
    padding: 15px 0px 0 15px;
  }

  .write-btn {
    font-size: 5vw;
    border-radius: 2vw;
    border: 1px solid #20BAEE;
    margin-right: 2vw;
  }
  
}