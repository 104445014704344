
.intro {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 195px;
}

.intro .bg {
  width: 61.4583vw;
  height: 24.583vw;
  border-radius: 12.265625vw;
  border: solid 1px #e5e5e5;
  background-color: #fff;
}

.intro .bg img{
  margin-top: 1.5625vw;
  width: 61.4583vw;
}

.intro .bg-m {
  display: none;
}

.intro .tb1 {
  width: 100%;
  text-align: center;
  margin-top: 49px;
}

.intro .tb1 span{
  font-family: 'NanumSquareAcr';
  font-size: 24px;
  line-height: 1.5;
  color: #777;
}

.intro .tb1-m {
  display: none;
}

.intro .tb2 {
  width: 100%;
  text-align: center;
  margin-top: 194px;
}

.intro .tb2 span{
  font-family: 'Marcellus';
  font-size: 90px;
  line-height: 0.38;
  color: #f7f7f7;
}

.intro .tb3 {
  width: 100%;
  text-align: center;
  margin-top: -68px;
  margin-bottom: 76px;
}

.intro .tb3 span{
  font-family: 'ONE-Mobile-Regular';
  font-size: 36px;
  line-height: 0.94;
  color: #222;
}

.intro .ozArea {
  width: 100%;
}

.intro .ozArea .ozList {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.intro .ozArea .ozList li {
    width: 580px;
    height: auto;
    display:inline-block;
    margin: 9.5px 0px;
    border-radius: 10px;
    box-shadow: 3.9px 7px 21px 0 rgba(0, 0, 0, 0.08);
    background-color: #fff;
}

.intro .ozArea .ozList li .content{
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
}

.intro .ozArea .ozList li .content .el-6 {
  width: 136px;
  height: 136px;
  margin: 32px 0px 0px 0px;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid #e5e5e5;
}

.intro .ozArea .ozList li .content .el-6 img{
  width: 135px; height: 135px;
  object-fit: cover;
  object-position: top;
  border-radius: 50%;
}

.intro .ozArea .ozList li .content .el-7 {
  width: 136px;
  height: 136px;
  margin: 32px 0px 0px 0px;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid #e5e5e5;
}

.intro .ozArea .ozList li .content .el-7 img{
  margin: 18px;
  width: 100px; height: 100px;
  object-fit: contain;
  object-position: center;
  border-radius: 50%;
  transform:scale(1.3); 
}


.intro .ozArea .ozList li .content .tb4 {
  width: 100%;
  margin-top: 25px;
  text-align: center;
}

.intro .ozArea .ozList li .content .tb4 span{
  font-family: 'NanumSquareR';
  font-size: 24px;
  line-height: 1.42;
  color: #222;
}

.intro .ozArea .ozList li .content .tb5 {
  width: 100%;
  margin-top: 1px;
  text-align: center;
}

.intro .ozArea .ozList li .content .tb5 span{
    font-family: 'NanumSquareR';
    font-size: 18px;
    line-height: 1.89;
    color: #777;
}

.intro .ozArea .ozList li .content .telArea {
  margin-top: 10px;
  margin-bottom: 25px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.intro .ozArea .ozList li .content .telArea span {
  font-family: 'Lato-Bold';
  font-size: 18px;
  line-height: 1.89;
  text-align: left;
  color: #222;
}

.intro .ozArea .ozList li .content .telArea img {
  max-width: 29px;
  max-height: 22px;
  margin: 0px 5px 5px 0px;
}

.intro .ozArea .ozList li .content .rt {
  width: 580px;
  height: 1px;
  background-color: #e5e5e5;
}

.intro .ozArea .ozList li .content .bt {
  width: 100%;
  margin-left: 29px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.intro .ozArea .ozList li .content .title {
  margin-top: 20px;
  margin-bottom: 14px;
  font-family: 'NanumSquareBold';
  font-size: 18px;
  line-height: 1.89;
  text-align: center;
  color: #222;
}

.intro .ozArea .ozList li .content .pf {
  margin-top: 0px;
  font-family: 'NanumSquareAcr';
  font-size: 16px;
  line-height: 2.13;
  text-align: left;
  color: #777;
}

.intro .rt-33 {
  width: 1180px;
  height: 1px;
  margin: 90px 0px 109px 0px;
  background-color: #e5e5e5;
}
















@media screen and (min-width:769px) and (max-width:1180px) {
  .bgImg img{
    width: 100vw;
    height: 87.239vw;
    object-fit:cover;
  }
  
  .greetings {
    width: 100%;
    margin: -695px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
  }
  
  .greetings .content {
    width: 100%;
  }
  
  .greetings .content .txArea{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
  }
  
  .greetings .content .txArea .tb1 {
    width: 100%;  
    text-align: right;
  }
  
  .greetings .content .txArea .tb1 span{
    font-family: 'Marcellus';
    font-size: 130px;
    line-height: 0.26;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #222;
  }
  
  .greetings .content .txArea .tb2 {
    width: 100%;
    margin: 52px 0px 0px 0px;
    text-align: right;
  }
  
  .greetings .content .txArea .tb2 span{
    /* margin: 20px 58px 58px 59px; */
    font-family: 'ONE-Mobile-Regular';
    font-size: 36px;
    line-height: 1.39;
    color: #222;
  }
  
  .greetings .content .txAreaBt{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
  }
  
  .greetings .content .txAreaBt .tb1 {
    margin: 673px 0px 0px 0px;
    width: 100%;  
  }
  
  .greetings .content .txAreaBt .tb1 span{
    font-family: 'ONE-Mobile-Regular';
    font-size: 36px;
    line-height: 1.39;
    color: #222;
  }
  
  .greetings .content .txAreaBt .tb2 {
    width: 100%;
    margin: 56px 0px 0px 0px;
  }
  
  .greetings .content .txAreaBt .tb2 span{
    font-family: 'NanumSquareAcr';
    font-size: 18px;
    line-height: 1.56;
    color: #222;
  }
  
  .greetings .content .txAreaBt .tb3 {
    width: 100%;
    margin: 185px 0px 0px 0px;
  }
  
  .greetings .content .txAreaBt .tb3 span{
    font-family: 'Marcellus';
    font-size: 130px;
    line-height: 1.15;
    color: rgba(229, 229, 229, 0.67);
  }
  
  .greetings .content .txAreaBt .bg {
    margin: -592px 0px 200px 0px;
  }
  

}
















@media screen and (max-width:768px) {
  .intro {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 26.041vw;
  }

  .intro .bg {
    display: none;
  }

  .intro .bg-m {
    display: inline-block;
    width: 61.4583vw;
    height: 133.333vw;
    border-radius: 30.664vw;
    border: solid 1px #e5e5e5;
    background-color: #fff;
  }
  
  .intro .bg-m img{
    margin-top: 4.427vw;
    margin-left: 3.906vw;
    width: 53.645vw;
  }

  .intro .tb1 {
    display: none;
  }
  
  .intro .tb1-m {
    display: block;
    width: 100%;
    text-align: center;
    margin-top: 6.380vw;
  }
  
  .intro .tb1-m span{
    font-family: 'NanumSquareAcr';
    font-size: 4.427vw;
    line-height: 1.5;
    color: #777;
  }
  
  .intro .tb2 {
    margin-top: 13.151vw;
  }
  
  .intro .tb2 span{
    font-size: 11.71875vw;
  }

  .intro .tb3 {
    margin: -8.557vw 0vw 9vw 0vw;
  }
  
  .intro .tb3 span{
    font-size: 5.2083vw;
    line-height: 0.85;
  }
  
  .intro .ozArea {
    width: 100%;
  }
  
  .intro .ozArea .ozList {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .intro .ozArea .ozList li {
      width: 92.447vw;
      height: auto;
      display:inline-block;
      margin: 1.302vw 0vw;
      border-radius: 1.302vw;
      box-shadow: 0.5078125vw 0.9114vw 2.734375vw 0 rgba(0, 0, 0, 0.08);
      background-color: #fff;
  }
  
  .intro .ozArea .ozList li .content{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
  }
  
  .intro .ozArea .ozList li .content .el-6 {
    margin: 4.1666vw 0vw 0vw 0vw;
  }
  
  .intro .ozArea .ozList li .content .el-7 {
    margin: 4.1666vw 0vw 0vw 0vw;
  }

  .intro .ozArea .ozList li .content .tb4 {
    width: 100%;
    margin-top: 2.734375vw;
  }
  
  .intro .ozArea .ozList li .content .tb4 span{
    font-family: 'NanumSquareR';
    font-size: 4.6875vw;
    line-height: 0.94;
    text-align: center;
    color: #222;
  }
  
  .intro .ozArea .ozList li .content .tb5 {
    width: 100%;
    margin-top: 2.4739vw;
  }
  
  .intro .ozArea .ozList li .content .tb5 span{
      font-family: 'NanumSquareR';
      font-size: 3.125vw;
      line-height: 1.42;
      text-align: center;
      color: #777;
  }
  
  .intro .ozArea .ozList li .content .telArea {
    margin-top: 10px;
    margin-bottom: 25px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  
  .intro .ozArea .ozList li .content .telArea span {
    font-family: 'Lato-Bold';
    font-size: 2.8645vw;
    line-height: 1.89;
    text-align: left;
    color: #222;
  }
  
  .intro .ozArea .ozList li .content .telArea img {
    max-width: 3.7760vw;
    max-height: 2.8645vw;
    margin: 0px 5px 5px 0px;
  }
  
  .intro .ozArea .ozList li .content .rt {
    width: 92.44791vw;
    height: 1px;
    background-color: #e5e5e5;
  }
  
  .intro .ozArea .ozList li .content .bt {
    margin-top: 2.6458vw;
    margin-bottom: 3.90625vw;
  }
  
  .intro .ozArea .ozList li .content .title {
    display: none;
  }
  
  .intro .ozArea .ozList li .content .pf {
    width: 70.44791vw;
    margin-top: 0px;
    margin-left: 4.036vw;
    font-family: 'NanumSquareAcr';
    font-size: 3.6458vw;
    line-height: 1.79;
    text-align: left;
    color: #444;
  }
  
  .intro .rt-33 {
    width: 92.1875vw;
    height: 1px;
    margin: 13.02vw 0vw;
    background-color: #e5e5e5;
  }
  

}