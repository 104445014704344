.partners {
  padding-bottom: 200px;
  word-break: keep-all;
}

.partners .parters-list-box:not(:first-child) {
  margin-top: 100px;
}

.partners .partners-title {
  display: flex;
  align-items: center;
  font-family: 'ONE-Mobile-Regular';
  font-size: 24px;
  font-weight: bold;
  line-height: 1.25;
}

.partners .partners-title .partners-title-dot{
  width: 6px;
  height: 6px;
  background-color: #201d1d;
  border-radius: 50px;
}

.partners .partners-title .partners-title-span{
  margin-left: 14px;
}

.partners .partners-sub {
  font-family: 'ONE-Mobile-Regular';
  font-size: 18px;
  font-weight: normal;
  margin-top: 19px;
  margin-left: 21px;
}

.partners .partners-sub span{
}

.partners .partners-logo .logo-box{
  width: 33%;
  border: solid 1px #e5e5e5;
}

table.partners-table {
  width: 100%;
  margin-top: 47px;
}

table.partners-table > tbody > tr {
  border-collapse: collapse;
  height: 180px;
}

table.partners-table > tbody > tr > td{
  border: solid 1px #e5e5e5;
  text-align: center;
  vertical-align: middle;
  width: 33%;
}

@media screen and (max-width: 768px) {
  .partners {
    padding-bottom: 26.04vw;
    word-break: keep-all;
  }

  .partners .parters-list-box:not(:first-child) {
    margin-top: 13.02vw;
  }

  .partners .partners-title {
    position: relative;
    font-size: 4.427vw;
  }

  .partners .partners-title .partners-title-dot{
    position: absolute;
    top: 2.4vw;
    width: 0.78vw;
    height: 0.78vw;
  }

  .partners .partners-title .partners-title-span{
    margin-left: 2.08vw;
  }

  .partners .partners-sub {
    width: 92%;
    margin-top: 5.47vw;
    margin-left: 2.08vw;
    font-size: 3.64583vw;
  }

  table.partners-table {
    margin-top: 7.03vw;
  }

  table.partners-table > tbody > tr {
    height: 14.58vw;
  }


  table.partners-table > tbody > tr > td{
    width: 33%;
    padding: 0 2.21vw;
  }

  table.partners-table > tbody > tr > td > img{
    width: 100%;
  }
  
}