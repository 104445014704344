.react-select-container {
  width: auto;
  border: 0px;
}

.react-select-container span {
  display: none;
}


.react-select-container > div {
  font-family: 'Rajdhani';
  font-size: 15px;
  font-weight: bold;
  line-height: 2;
  color: #222;
  border: 0px;
  cursor: pointer;
  /* background-color: #fff; */
}

.react-select-container div {
  font-family: 'Rajdhani';
  font-size: 15px;
  font-weight: bold;
  line-height: 2;
  color: #222;
  border: 0px;
  cursor: pointer;
  /* background-color: transparent; */
}

.navArea {
  margin-top: 58px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}


.navArea .bg {
  width: 650PX;
  height: 314px;
}

.navArea .bg img{
  width: 100%;
  height: 100%;
  position: relative;
  z-index: -1;
  object-fit: cover;
}


.navArea .contentNav{
  margin-top: 28px;
}

.navArea .contentNav .info {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: baseline;
  align-items: center;
}

.navArea .contentNav .info .tx1 {
  font-family: 'Rajdhani';
  font-size: 15px;
  font-weight: 600;
  line-height: 2;
  color: #999;
}

.navArea .contentNav .info .tx1 a{
  font-family: 'Rajdhani';
  font-size: 15px;
  font-weight: 600;
  line-height: 2;
  color: #999;
}

.navArea .contentNav .info .tx2{
  font-family: 'Rajdhani';
  font-size: 15px;
  font-weight: bold;
  line-height: 2;
  color: #222;
}

.navArea .contentNav .info .el-5 {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #999;
  margin-right: 1.0416vw;
  margin-left: 1.0416vw;
}

.navArea .contentNav .info .ic img {
  width: 11px;
  height: 7px;
  margin-left: 0.5416vw;
}

.navArea .contentNav .title {
  margin-top: 30px;
  margin-left: -6px;
  font-family: 'ONE-Mobile-Regular';
  font-size: 54px;
  font-weight: bold;
  line-height: 1;
  color: #222;
}

.navArea .contentNav .link {
  margin-top: 79px;
  display: flex;
  flex-wrap: wrap;
}

.navArea .contentNav .link li{
  margin-right: 28px;
}

.navArea .contentNav .link li div {
  display: flex;
  flex-direction: column;
}

.navArea .contentNav .link li span{
  font-family: 'ONE-Mobile-Regular';
  font-size: 18px;
  font-weight: 100;
  line-height: 1.67;
  color: #999;
}


.navArea .contentNav .link li span.isHit{
  font-family: 'ONE-Mobile-Regular';
  font-size: 18px;
  font-weight: bold;
  line-height: 1.67;
  color: #1d99df;
}

.navArea .contentNav .link li .rect{
  width: auto;
  height: 2px;
  margin-top: 7px;
  background-color: #1d99df;
}

.line {
  width: 100vw;
  height: 1px;
  background-color: #E5E5E5;
  margin-bottom: 130px;
}











@media screen and (min-width:769px) and (max-width:1180px) {

  .navArea .contentNav{
    margin-top: 58px;
  }
  
  .navArea .contentNav .info {
    width: 100%;
    /* display: flex;
    justify-content: space-between;
    align-items: center; */
  }
  
  .navArea .contentNav .info .tx1{
    font-family: 'Rajdhani';
    font-size: 15px;
    font-weight: 600;
    line-height: 2;
    color: #999;
  }
  
  .navArea .contentNav .info .tx2{
    font-family: 'Rajdhani';
    font-size: 15px;
    font-weight: bold;
    line-height: 2;
    color: #222;
  }
  
  .navArea .contentNav .info .el-5 {
    width: 5px;
    height: 5px;
    border-radius: 50%;
  }
  
  .navArea .contentNav .info .ic img {
    width: 11px;
    height: 7px;
  }
  
  .navArea .contentNav .title {
    margin-top: 30px;
    margin-left: -6px;
    font-family: 'ONE-Mobile-Regular';
    font-size: 60px;
    font-weight: bold;
    color: #222;
  }
  
  .navArea .contentNav .link {
    margin-top: 79px;
    display: flex;
  }
  
  .navArea .contentNav .link li{
    margin-right: 28px;
  }
  
  .navArea .contentNav .link li div {
    display: flex;
    flex-direction: column;
  }
  
  .navArea .contentNav .link li span{
    font-family: 'ONE-Mobile-Regular';
    font-size: 18px;
    font-weight: bold;
    line-height: 1.67;
    color: #999;
  }
  
  .line {
    margin-bottom: 30px;
  }
}



















@media screen and (max-width:768px) {
  .navArea .bg {
    display: none;
  }

  .navArea .contentNav{
    width: 100%;
    margin-top: 0vw;
  }
  
  .navArea .contentNav .info {
    width: 100%;
    /* display: flex;
    align-items: center; */
  }
  
  .navArea .contentNav .info .tx1{
    font-family: 'Rajdhani';
    font-size: 3.385vw;
    font-weight: 600;
    line-height: 2;
    color: #999;
    margin-right: 0vw;
  }
  
  .navArea .contentNav .info .tx2{
    font-family: 'Rajdhani';
    font-size: 3.385vw;
    font-weight: bold;
    line-height: 2;
    color: #222;
    margin-right: 0vw;
  }
  
  .navArea .contentNav .info .el-5 {
    width: 0.651vw;
    height: 0.651vw;
    border-radius: 50%;
    margin-right: 2vw;
    margin-left: 2vw;
  }
  
  .navArea .contentNav .info .ic img {
    width: 2.21354vw;
    height: 1.432291vw;
    margin-left: 1.2vw;
  }
  
  .navArea .contentNav .title {
    margin-bottom: 18.75vw;
    margin-top: 3.906vw;
    margin-left: -0.781vw;
    font-family: 'ONE-Mobile-Regular';
    font-size: 7.8125vw;
    font-weight: bold;
    line-height: 1;
    color: #222;
  }
  
  .navArea .contentNav .link {
    display: none;
  }
  
  .line {
    margin-bottom: 30px;
  }

}