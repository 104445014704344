.bg-main-board{
  height: auto;
}

.board-area{
  display: flex;
  justify-content: space-between;
}

.bg-main-board .board-list{
  margin-top: 100px;
  margin-bottom: 100px;
  width: 47%;
}

.bg-main-board .board-list .top {
  display: flex;
  justify-content: space-between;
}

.bg-main-board .board-list .top .title {
  font-family: 'ONE-Mobile-Title';
  font-size: 48px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.25;
  text-align: left;
  color: #222;
} 

.bg-main-board .board-list .top .sm{
  font-family: 'NanumSquareAcr';
  font-size: 16px;
  line-height: 3.75;
  text-align: center;
  color: #777;
} 

.bg-main-board .board-list .bottom {
  font-family: 'NanumSquareAcr';
  font-size: 16px;
  line-height: 2.25;
  color: #222;
  text-align: left;
}

.bg-main-board .board-list .bottom ul li:first-child{
  border: 0;
}

.bg-main-board .board-list .bottom ul li{
  border: 0;
  padding: 5px 0px;
}

.bg-main-board .board-list .bottom ul li .board-title,.board-date{
  font-family: 'NanumSquareAcr';
  font-size: 15px;
  color: #222;
}

.bg-main-board .board-list .bottom ul li a{
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.bg-main-board .board-list .bottom .reply {
    width: 54px;
    height: 24px;
    padding: 0px 5px 10px 5px;
    border-radius: 15px;
    background-color: #2c7eea;
}
.bg-main-board .board-list .bottom .ready {
  width: 54px;
  height: 24px;
  padding: 0px 5px 10px 5px;
  border-radius: 15px;
  background-color: #c1c1c1;
}

.bg-main-board .board-list .bottom span{
    font-family: 'NanumSquareAcr';
    font-size: 12px;
    line-height: 3;
    color: #fff;
}


.bg-main-board .board-list .rt {
  width: 100%;
  height: 2px;
  margin: 30px 100px 30px 0px;
  background-color: #222;
}








@media screen and (max-width:768px) {
  
.bg-main-intro{
  margin-top: 0px;
}

.main-intro{
  text-align: center;
  padding: 26.041vw 0vw 0vw 0vw;
  word-break: keep-all;
}

.board-area {
  flex-direction: column;
}

.bg-main-board{
  margin: 50px 0px;
}

.bg-main-board .board-list{
  margin: 50px 0px;
  width: 100%;
}

.bg-main-board .board-list .top .title {
  font-size: 6.25vw;
  line-height: 1.25;
} 

.bg-main-board .board-list .bottom ul li .board-title,.board-date{
  font-size: 3.645vw;;
}

.bg-main-board .board-list .bottom .reply {
  width: 43px;
}

.bg-main-board .board-list .rt {
  height: 1px;
  margin: 10px 0px 20px 0px;
}

.main-intro .main-intro-top { 
  font-family: 'NanumSquareAcr';
  font-size: 3.906vw;
  line-height: 2.5;
  color: #2c7eea;
}
.main-intro .main-intro-title { 
  font-family: 'ONE-Mobile-Title';
  font-size: 6.25vw;
  line-height: 1.25;
}
.main-intro .main-intro-sub { 
  font-family: 'NanumSquareAcr';
  font-size: 3.645vw;
  line-height: 1.58;
  color: #777;
  
  word-break: keep-all;
}
.main-intro .mt50{
  margin-top: 5.208vw;
}

.main-intro .mt70{
  margin-top: 7.812vw;
}

.main-intro .main-intro-button{
  margin-top: 5.859vw;
  margin-bottom: 26.041vw;
  width: 37.369vw;
  height: 9.765vw;
  border-radius: 33.5px;
  background-color: #2c7eea;
  font-family: 'NanumSquareBold';
  font-size: 3.645vw;
  line-height: 2.11;
  color: #fff;
}
}
