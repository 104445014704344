/* .container {
  display: flex;
  justify-content: center;
}

.content-box {
  width: 1180px;
} */

.reservation {
  display: flex;
  flex-direction: column;
  justify-items: center;
}
 
.reservation .reservation-title{
  width: 80%;
  font-family: 'NanumSquareR';
  font-size: 16px;
  line-height: 2.5;
  color: #777;
  text-align: center;
}

.reservation .input-area {
  width: 100%;
} 

.reservation .input-area .input-unit {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reservation .input-area .input-unit .input-hook{
  width: 590px;
  height: 62px;
  padding: 0px 0px 0px 25px;
}

.reservation .input-area .input-unit #content{
  resize: none;
  width: 590px;
  height: 228px;
  padding: 25px 0px 0px 25px;
  display: block;
  font-family: 'NanumSquareR';
  font-size: 16px;
  margin: 20px 0px 20px 0px;
  border-radius: 10px;
  border: solid 1px #e5e5e5;
  background-color: #fff;
}

.reservation .input-area .input-unit #content::placeholder {
  position: absolute;
  left: 25px;
  top: 25px;
  color: #999;
  font-style: 'NanumSquareR';
}


.reservation .input-area .input-unit input{
  display: block;
  font-family: 'NanumSquareR';
  font-size: 16px;
  margin: 20px 0px 20px 0px;
  border-radius: 10px;
  border: solid 1px #e5e5e5;
  background-color: #fff;
}

.reservation .input-area .input-unit .error-message{
  color: red;
  font-size: 12px;
  text-align: left;
}

.reservation .input-area .input-unit input::placeholder {
  color: #999;
  font-style: 'NanumSquareR';
}

.reservation .input-area .board-btn-area {
  display: flex;
  justify-content: center;
  margin: 50px 8px 210px 0px;
}

.reservation .input-area .board-btn-area .write-btn {
  cursor: pointer;
  width: 292px;
  height: 69px;
  font-size: 0.8333vw;
  color: #fff;
  margin: 0px 29px 0px 0px;
  border-radius: 10px;
  background-color: #1d99df;
}

.reservation .input-area .board-btn-area .cancel-btn {
  cursor: pointer;
  width: 292px;
  height: 69px;
  font-size: 0.8333vw;
  color: #777;
  border-radius: 10px;
  border: solid 1px #e5e5e5;
  background-color: #fff;
}















@media screen and (max-width:768px) {
  .reservation .reservation-title{
    width: 100%;
    font-size: 3.3854vw;
    line-height: 1.54;
    text-align: left;
  }

  .reservation .input-area .input-unit .input-hook{
    width: 88.9325vw;
    height: 9.765625vw;
    padding: 0 0 0 3.255vw;
  }

  .reservation .input-area .input-unit #content{
    resize: none;
    width: 88.9325vw;
    height: 29.6875vw;
    padding: 3.255vw 0px 0px 3.255vw;
    display: block;
    font-family: 'NanumSquareR';
    font-size: 16px;
    margin: 2.60416vw 0px;
    border-radius: 10px;
    border: solid 1px #e5e5e5;
    background-color: #fff;
  }
  
  .reservation .input-area .board-btn-area .write-btn {
    width: 31.901vw;
    height: 9.1145vw;
    font-size: 3.3854vw;
    margin: 0px 1.302vw 0px 0px;
    border-radius: 1.302vw;
  }
  
  .reservation .input-area .board-btn-area .cancel-btn {
    cursor: pointer;
    width: 31.901vw;
    height: 9.1145vw;
    font-size: 3.3854vw;
    border-radius: 1.302vw;
  }

  .reservation .input-area .board-btn-area {
    margin: 6.51041vw 0px 17.578125vw 0px;
  }
  
  
}
