

.bg-main-quick-menu{
  background-image: linear-gradient(70deg, #5499ee, #79ced2);
}

.main-quick {
  padding: 100px 0px;
  word-break: keep-all;
}

.main-quick .main-quick-title{
  font-family: 'Montserrat';
  font-size: 3.6rem;
  font-weight: bold;
  line-height: 1.06;
  color: #fff;
}

.main-quick .main-quick-sub{
  font-family: 'NanumSquareAcr';
  font-size: 24px;
  line-height: 1.58;
  color: #fff;
  margin-top: 20px;
}

.main-quick .quick-list{
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.main-quick .quick-list a{
  width: 24%;
}

.main-quick .quick-list .quick-list-el{
  width: 100%;
  height: 100%;
  border-radius: 30px;
  background-color: #fff;
}

.main-quick .quick-list .el-info{
  padding: 17% 10% 17% 10%;
}

.main-quick .quick-list .quick-list-el .quick-title{
  font-family: 'NanumSquareAcr';
  font-size: 18px;
  color: #20baee;
}

.main-quick .quick-list .quick-list-el .title-eng{
  font-family: 'NanumSquareAcr';
  font-size: 16px;
  color: #777;
  margin-top: 20%;
}

.main-quick .quick-list .quick-list-el .title-kor{
  font-family: 'NanumSquareBold';
  font-size: 22px;
  color: #222;
  margin-top: 6%;
}

.main-quick .quick-list .quick-list-el .quick-detail{
  display: block;
  font-family: 'NanumSquareAcr';
  font-size: 16px;
  color: #444;
  margin-top: 12%;
  line-height: 1.63;
}







@media screen and (min-width:769px) and (max-width:1180px) {

  .main-quick .quick-list .quick-list-el{
    border-radius: 1.5625vw;
  }
  
  .main-quick .quick-list .quick-list-el .quick-title{
    font-size: 1.5vw;
  }
  
  .main-quick .quick-list .quick-list-el .title-eng{
    font-size: 1.333vw;
  }
  
  .main-quick .quick-list .quick-list-el .title-kor{
    font-size: 1.833vw;
  }
  
  .main-quick .quick-list .quick-list-el .quick-detail{
    font-size: 1.333vw;
  }
  
}





@media screen and (max-width: 768px){

  .main-quick {
    padding: 18.75vw 0vw;
    word-break: keep-all;
  }
  
  .main-quick .main-quick-title{
    display: none;
  }
  
  .main-quick .main-quick-sub{
    display: none;
  }
  
  .main-quick .quick-list{
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .main-quick .quick-list a{
    width: 92.447vw;
    /* height: 38.02vw; */
    height: 40.02vw;
    margin: 1.302vw 0;
  }
  
  .main-quick .quick-list .quick-list-el{
    border-radius: 3.90vw;
  }
  
  .main-quick .quick-list .el-info{
    padding: 0 0 0 5.46875vw;
  }
  
  .main-quick .quick-list .quick-list-el .quick-title{
    font-size: 3.645vw;
    padding-top: 5.46875vw;
  }
  
  .main-quick .quick-list .quick-list-el .title-eng{
    font-size: 3.90vw;
    margin-top: 5.338vw;
  }
  
  .main-quick .quick-list .quick-list-el .title-kor{
    font-size: 4.6875vw;
    margin-top: 1.953vw;
  }
  
  .main-quick .quick-list .quick-list-el .quick-detail{
    font-size: 3.645vw;
    margin-top: 5.078vw;
    padding-bottom: 5.078vw;
  }
}