.container {
  display: flex;
  justify-content: center;
}

.container2 {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  background-color: #f7f7f7;
}

.container3 {
  display: flex;
  justify-content: center;
  background-color: #f7f7f7;
}

.content-box {
  width: 1180px;
}

.detail-box{
  margin-bottom: 100px;
}

.mainLayer{
  width: 100%;
  position: absolute;
  margin-top: -97px;
  z-index: -1;
}

.mainLayer img {
  width: 100%;
}

.mMainLayer{
  display: none;
}

.mMainLayer img {
  width: 100%;
}

.floating {
  position: fixed;
	right: 50px;
	bottom: 130px;
}

.floating img {
  cursor: pointer;
  margin-top: 10px;
  background-color: transparent;
}

.floating .tel {
  display: flex;
  width: 211px;
  height: 98px;
  margin-top: 10px;
  background-color: #2c7eea;
  border-radius: 50px 0px 0px 50px;
  align-items: center;
  justify-content: space-around;
}

.floating .tel .tx-area{
  display: flex;
  flex-direction: column;
}

.floating .tel .tx1{
  font-family: 'NanumSquareR';
  font-size: 15px;
  line-height: 1.47;
  color: #fff;

}

.floating .tel .tx2{
  font-family: 'Lato';
  font-size: 18px;
  font-weight: bold;
  line-height: 1.22;
  color: #fff;
}

.floating .tel img{
  object-fit: none;
}


@media screen and (min-width:769px) and (max-width:1180px) {
  .container{
    padding: 0vw 3.776vw;
  }

  .container2 {
    padding: 0vw 3.776vw;
  }

  .container3 {
    padding: 0vw 3.776vw;
  }

  /* .content-box {
    width: 100%;
  } */

  .mainLayer{
    display: none;
  }

  .mMainLayer{
    display: block;
    position: absolute;
    top: 32.2vw;
    z-index: -1;
  }
}

@media screen and (max-width:768px) {
  .container{
    padding: 0px 4.166vw;
  }

  .container2 {
    padding: 0px 4.166vw;
  }

  .container3 {
    padding: 0px 4.166vw;
  }

  .content-box {
    width: 100%;
  }

  .mainLayer{
    display: none;
  }

  .mMainLayer{
    display: block;
    position: absolute;
    top: 32.2vw;
    z-index: -1;
  }

  .floating {
    position: fixed;
    right: 6.51041vw;
    bottom: 16.927vw;
  }

  .floating img {
    width: 9.11458333vw;
    height: 9.11458333vw;
    cursor: pointer;
    margin-top: 10px;
    background-color: transparent;
  }

  .floating .tel {
    display: none;
  }
}