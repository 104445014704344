

.secsix-left-arrow a{
  cursor: pointer;
}

.secsix-left-arrow .pillar{
  width: 1px;
  height: 15px;
  background-color: #ddd;
  margin: 0px 15px;
}

.main-section-six {
  display: flex;
  justify-content: space-between;
  height: 422px;
  padding: 200px 0px;
}

.six-left-section {
  display: flex;
  flex-direction: column;
}

.six-left-section .secsix-left {
  width: 540px;
  display: flex;
  flex-direction: column;
}

.six-left-section .secsix-left .main-refer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #111;
  padding-bottom: 30px;
}



.six-left-section .secsix-left .secsix-left-title{
  font-size: 3rem;
  line-height: 1.25;
  color: #111;
  font-family: 'ONE-Mobile-Title';
}

.six-left-section .secsix-left .secsix-left-arrow{
  display: flex;
  align-items: center;
}

.six-left-section .secsix-left .secsix-left-arrow .arrow-title{
  font-family: 'NanumSquareAcr';
  font-size: 16px;
  line-height: 3.75;
  color: #777;
  margin-right: 22px;
}

.six-left-section .secsix-left .main-refer-list{
  display: flex;
  align-items: center;
  padding: 25px 0px;
  border-bottom: 1px solid #e5e5e5;;
}

.six-left-section .secsix-left .main-refer-list .main-date-box{
  padding: 0px 30px;
}

.six-left-section .secsix-left .main-refer-list .pillar{
  width: 1px;
  height: 15px;
  background-color: #ddd;
  margin: 0px 30px 0px 0px;
}

.six-left-section .secsix-left .main-refer-list .main-refer-date{
  font-family: 'ONE-Mobile-Title';
  font-size: 36px;
  text-align: center;
}

.six-left-section .secsix-left .main-refer-list .main-refer-date-two{
  font-family: 'ONE-Mobile-Title';
  font-size: 12px;
}

.six-left-section .secsix-left .main-refer-list .main-refer-list-title{
  display: block;
  font-family: 'NanumSquareAcr';
  font-size: 16px;
  line-height: 3.75;
}

.six-left-section .secsix-left .main-refer-list .main-refer-list-title-mobile{
  display: none;
}


.main-section-six .six-right-section {
  width: 100%;
  height: 422px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  word-break: keep-all;
}

.secsix-right .list-underline{
  border-bottom: 1px solid #e5e5e5;
}


.secsix-right .main-question-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 70px 0px;
}


.secsix-right .main-question-list .six-right-content .r-cont-title{
  font-family: 'ONE-Mobile-Title';
  font-size: 30px;
  line-height: 2;
}

.secsix-right .main-question-list .six-right-content .r-cont-detail{
  font-family: 'NanumSquareAcr';
  font-size: 18px;
  color: #444; 
}

.secsix-right .main-question-list .six-right-icon {
  padding: 26px;
  border-radius: 50px;
  box-shadow: 3.4px 6.1px 21px 0 rgba(234, 237, 255, 0.86);
}








@media screen and (min-width:769px) and (max-width:1180px) {
  
  .main-section-six {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    height: auto;
    padding: 13.02vw 0vw;
  }
  
}







@media  screen and (max-width: 768px) {
  .main-section-six {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    padding: 26.041vw 0vw 18.229vw 0vw;
  }
  
  .six-left-section {
    width: 100%;
  }
  
  .six-left-section .secsix-left {
    width: 100%;
  }

  .six-left-section .secsix-left .secsix-left-title{
    font-size: 6.25vw;
  }
  
  .six-left-section .secsix-left .secsix-left-arrow .arrow-title{
    font-size: 3.125vw;
  }

  .six-left-section .secsix-left .main-refer{
    padding-bottom: 1.302vw;
  }

  .six-left-section .secsix-left .secsix-left-title{
    font-size: 6.25vw;
  }

  .six-left-section .secsix-left .main-refer-list{
    padding: 5.208vw 0px;
  }

  .six-left-section .secsix-left .main-refer-list .main-refer-date{
    font-size: 9.114vw;
  }
  
  .six-left-section .secsix-left .main-refer-list .main-refer-date-two{
    font-size: 3.125vw;
  }


  .six-left-section .secsix-left .main-refer-list .main-refer-list-title{
    display: none;
  }

  .six-left-section .secsix-left .main-refer-list .main-refer-list-title-mobile{
    font-size: 3.4vw;
    display: block;
  }

  .secsix-right {
    width: 100%;
  }

}