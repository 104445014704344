.bg-black{
  background-color: #111;
}

.footer {
  display: flex;
  height: 166px;
  padding: 78px 0px;
}

.f-left-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 166px;
}

.f-left-top {
  font-size: 10px;
}

.footer-cont {
  color: white;
  font-size: 3.6em;
  line-height: 1.33;
  font-family: 'NanumSquareBold';
}

.footer-cont-mobile{
  display: none;
}

.f-right-section {
  margin-left: 112px;
}

.f-nav ul {
  font-size: 10px;
}
.f-nav ul li{
  display: inline-block;
}

.f-nav ul li a{
  color: #fff;
  margin-right: 22px;
  font-size: 1.8em;
  line-height: 1.89;
  font-family: 'NanumSquareAcr';
}

.f-info{
  display: block;
  font-size: 14px;
  line-height: 1.57;
  color: #999;
  font-family: 'NanumSquareAcr';
}

.f-info-mobile{
  display: none;
  color: #999;
  font-family: 'NanumSquareAcr';
}

.f-copy {
  font-size: 14px;
  line-height: 1.57;
  color: #999;
  font-family: 'NanumSquareAcr';
}







@media screen and (min-width:769px) and (max-width:1180px) {
  /* .footer {
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 10.156vw 0vw;
  }

  .f-left-section {
    justify-content: flex-start;
    height:auto;
  }

  .footer-cont{
    display: none;
  }
  .footer-cont-mobile{
    display: block;
    font-size: 4.6875vw;
    line-height: 1.33;
    color: #fff;
  }

  .f-left-bottom{
    margin-top: 4.166vw;
    width: 29.9479vw;
    height: 6.901vw;
  }

  .f-left-bottom img{
    width: 100%;
  }

  .f-right-section{
    margin-left: 0px;
    margin-top: 11.588vw;
  }

  .f-nav ul li{
    font-size: 3.645vw;
    line-height: 1.36;
    display: inline-block;
    margin-right: 2.734vw;
  }
  
  .f-info{
    display: none;
  }
  .f-info-mobile{
    padding-top: 5.598vw;
    padding-bottom: 5.598vw;
    display: block;
    font-size: 3.0vw;
    line-height: 1.42;
  }
  
  .f-copy {
    font-size: 3.125vw;
    line-height: 1.42;
  }

  .f-left-bottom{
    width: 30%;
  } */

}









@media screen and (max-width:768px) {
  .footer {
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 10.156vw 0vw;
  }

  .f-left-section {
    justify-content: flex-start;
    height:auto;
  }

  .footer-cont{
    display: none;
  }
  .footer-cont-mobile{
    display: block;
    font-size: 4.6875vw;
    line-height: 1.33;
    color: #fff;
  }

  .f-left-bottom{
    margin-top: 4.166vw;
    width: 29.9479vw;
    height: 6.901vw;
  }

  .f-left-bottom img{
    width: 100%;
  }

  .f-right-section{
    margin-left: 0px;
    margin-top: 11.588vw;
  }

  .f-nav ul li{
    font-size: 3.645vw;
    line-height: 1.36;
    display: inline-block;
    margin-right: 2.734vw;
  }

  .f-nav ul li a{
    margin-right: 1vw;
    font-size: 3.64583vw;
  }
  
  .f-info{
    display: none;
  }
  .f-info-mobile{
    padding-top: 5.598vw;
    padding-bottom: 5.598vw;
    display: block;
    font-size: 3.125vw;
    line-height: 1.42;
  }
  
  .f-copy {
    font-size: 3.125vw;
    line-height: 1.42;
  }

  .f-left-bottom{
    width: 30%;
  }

}

