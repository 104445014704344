

.bg-youtube {
  background:url(../../images/main/youtube_bg.png) no-repeat;
}

.youtube_bg_m {
  display: none;
}

.youtube {
  padding: 150px 0px;
  text-align: center;
}

.youtube .youtube-main-title{
  font-family: 'ONE-Mobile-Title';
  font-size: 4.8rem;
  line-height: 1.25;
  word-break: keep-all;
}

.youtube .youtube-logo{
  margin-top: 50px;
}

.youtube .youtube-video{
  margin-top: 80px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.youtube .youtube-video .youtube-thumbnail{
  width: 590px;
  /* 속기형(순서 중요)*/
  height: 391px;
  border-radius: 50px;
}

.youtube .youtube-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 490px;
  margin-left: 53px;
}

.youtube .youtube-info .youtube-date{
  font-family: 'NanumSquareAcr';
  font-size: 18px;
  line-height: 1.56;
  color: #20baee;
  text-align: left;
}
.youtube .youtube-info .youtube-title{
  margin-top: 50px;
  font-family: 'NanumSquareBold';
  font-size: 30px;
  line-height: 1.27;
  text-align: left;
}
.youtube .youtube-info .youtube-intro{
  margin-top: 31px;
  font-family: 'NanumSquareAcr';
  font-size: 18px;
  line-height: 1.56;
  color: #444;
  text-align: left;
}

.youtube-arrow-l {
  display: block;
  position: absolute;
  left: -100px;
  top: 50%; transform:translateY(-50%);
}

.youtube-arrow-r {
  display: block;
  position: absolute;
  right: -100px;
  top: 50%; transform:translateY(-50%);
}








@media screen and (min-width:769px) and (max-width:1180px) {
    .bg-youtube {
      background: none;
    }
  
    .youtube {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 29.473vw;
      padding-bottom: 26.041vw;
    }
  
    .youtube_bg_m {
      display: block;
      position: absolute;
      top: -22.567vw;
      z-index: -1;
      left: 0px;
      text-align: left;
    }
  
    .youtube_bg_m img{
      width: 67.708vw;
    }
  
    .youtube .youtube-main-title{
      width: 80%;
      font-family: 'ONE-Mobile-Title';
      font-size: 6.25vw;
      line-height: 1.25;
      word-break: keep-all;
    }
  
    .youtube .youtube-logo{
      margin-top: 50px;
      width: 26.822vw;
      height: 7.942vw;
    }
  
    .youtube-arrow-l {
      display: none;
    }
    
    .youtube-arrow-r {
      display: none;
    }
  
    .youtube .youtube-video {
      flex-direction: column;
    }
    .youtube .youtube-video .youtube-thumbnail{
      width: 92.395vw;
      height: 61.223vw;
      border-radius: 50px;
    }
    .youtube .youtube-info {
      width: 100%;
      margin-left: 0px;
    }
  
    .youtube .youtube-info .youtube-date{
      font-size: 3.385vw;
      margin-top: 10%;
    }
    .youtube .youtube-info .youtube-title{
      font-size: 5.208vw;
      margin-top: 2%;
    }
    .youtube .youtube-info .youtube-intro{
      font-size: 3.385vw;
      margin-top: 5%;
    }
  
  }









@media screen and (max-width: 768px){

  .bg-youtube {
    background: none;
  }

  .youtube {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 29.473vw;
    padding-bottom: 26.041vw;
  }

  .youtube_bg_m {
    display: block;
    position: absolute;
    top: -22.567vw;
    z-index: -1;
    left: 0px;
    text-align: left;
  }

  .youtube_bg_m img{
    width: 67.708vw;
  }

  .youtube .youtube-main-title{
    width: 80%;
    font-family: 'ONE-Mobile-Title';
    font-size: 6.25vw;
    line-height: 1.25;
    word-break: keep-all;
  }

  .youtube .youtube-logo{
    margin-top: 50px;
    width: 26.822vw;
    height: 7.942vw;
  }

  .youtube-arrow-l {
    display: none;
  }
  
  .youtube-arrow-r {
    display: none;
  }

  .youtube .youtube-video {
    flex-direction: column;
  }
  .youtube .youtube-video .youtube-thumbnail{
    width: 92.395vw;
    height: 61.223vw;
    border-radius: 50px;
  }
  .youtube .youtube-info {
    width: 100%;
    margin-left: 0px;
  }

  .youtube .youtube-info .youtube-date{
    font-size: 3.385vw;
    margin-top: 10%;
  }
  .youtube .youtube-info .youtube-title{
    font-size: 5.208vw;
    margin-top: 2%;
  }
  .youtube .youtube-info .youtube-intro{
    font-size: 3.385vw;
    margin-top: 5%;
  }

}