.bc {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.bc .title {
  margin-top: 110px;
  font-family: 'ONE-Mobile-Regular';
  font-size: 48px;
  font-weight: bold;
  line-height: 1;
  color: #222;
}

.bc .type1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("../../images/common/business_body.png");
  background-repeat: no-repeat;
}

.bc .type1 .chart-area {
  width: 100%;
  display: flex;
  /* justify-content: center; */
}

.bc .type1 .chart-area .doc {
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
  margin-right: 100px;
}

.bc .type1 .chart-area .doc .rt {
  margin-top: 60px;
  display: flex;
  align-items: end;
  font-family: 'ONE-Mobile-Regular';
  font-size: 24px;
  font-weight: bold;
  line-height: 1.25;
  color: #222;
  margin-left: 15px;
  margin-bottom: 15px;
}

.bc .type1 .chart-area .doc .rt span{
  margin-left: 15px;
}

.bc .type1 .chart-area .doc .rt-3 {
    max-width: 540px;
    height: 1px;
    background-color: #444;
}

.bc .type1 .chart-area .doc .cont {
  max-width: 510px;
  margin-top: 50px;
  font-family: 'NanumSquareR';
  font-size: 18px;
  line-height: 1.44;
  color: #444;
}

.bc .type1 .chart-area .doc .cont1 {
  max-width: 61.458vw;
  margin-top: 50px;
  font-family: 'NanumSquareR';
  font-size: 18px;
  line-height: 1.44;
  color: #444;
}



.bc .type2 {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.bc .type2 .title-box {
  display: flex;
  align-items: end;
  font-family: 'ONE-Mobile-Regular';
  font-size: 48px;
  font-weight: bold;
  line-height: 1;
  color: #222;
}

.bc .type2 .title-box span{
  margin-left: 20px;
}

.bc .type2 .rt-4 {
  margin-top: 20px;
  height: 1px;
  background-color: #444;
}

.bc .type2 .content {
  padding: 0 0px;
}

.bc .type1 .info {
  width: 87%;
  text-align: center;
}

.bc .type2 .info {
  text-align: left;
}

.bc .type2 .sub-title {
  font-family: 'NanumSquareR';
  font-size: 18px;
  line-height: 1.67;
  color: #444;
  margin-top: 40px;
}

.bc .type2 .sub-info {
  font-family: 'NanumSquareR';
  font-size: 14px;
  line-height: 1.86;
  color: #777;
  margin-top: 40px;
}

.bc .type2 .chart-area {
  width: 100%;
  display: flex;
}

.bc .type2 .chart-area .doc {
  display: flex;
  flex-direction: column;
  margin-right: 100px;
}

.bc .type2 .chart-area .doc .rt {
  margin-top: 60px;
  display: flex;
  align-items: baseline;
  font-family: 'ONE-Mobile-Regular';
  font-size: 24px;
  font-weight: bold;
  line-height: 1.25;
  color: #222;
  margin-left: 15px;
  margin-bottom: 15px;
}

.bc .type2 .chart-area .doc .rt span{
  margin-left: 15px;
}

.bc .type2 .chart-area .doc .rt-3 {
    max-width: 540px;
    height: 1px;
    background-color: #444;
}

.bc .type2 .chart-area .doc .cont {
  max-width: 510px;
  margin-top: 50px;
  font-family: 'NanumSquareR';
  font-size: 18px;
  line-height: 1.44;
  color: #444;
}

.bc .type2 .chart-area .doc .cont1 {
  max-width: 61.458vw;
  margin-top: 50px;
  font-family: 'NanumSquareR';
  font-size: 18px;
  line-height: 1.44;
  color: #444;
}


.bc .type2 .chart-area .chart {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  border: 1px solid #e5e5e5;
}

.bc .type2 .chart-area .chart .rt-1 {
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70px;
  background-color: #1d99df;
  font-family: 'NanumSquareB';
  font-size: 22px;
  line-height: 1.36;
  text-align: center;
  color: #fff;
}

.bc .type2 .chart-area .chart .rt-2 {
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70px;
  background-color: #0d2a65;
  font-family: 'NanumSquareB';
  font-size: 22px;
  line-height: 1.36;
  text-align: center;
  color: #fff;
}




.bc .type3 {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  /* background-color: #f7f7f7; */
}


.bc .type3 .rt-top {
  width: 100%;
  height: 1px;
  margin: 46px auto;
  background-color: #222;
}

.bc .type3 .sub-title {
  font-family: 'ONE-Mobile-Regular';
  font-size: 24px;
  line-height: 1.25;
  color: #444;
  margin-top: 40px;
}

.bc .type3 .rt-btm {
  width: 100%;
  height: 1px;
  margin: 46px auto;
  background-color: #e5e5e5;
}

.bc .type3 .list-area {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.bc .type3 .list-area li{
  display: flex;
  align-items: center;
}

.bc .type3 .list-area .l-content .l-title{
  margin-right: 100px;
  margin-bottom: 33px;
  font-family: 'ONE-Mobile-Regular';
  font-size: 24px;
  font-weight: bold;
  line-height: 1.25;
  color: #222;
}

.bc .type3 .list-area .l-content .l-title1{
  margin-right: 100px;
  font-family: 'ONE-Mobile-Regular';
  font-size: 24px;
  font-weight: bold;
  line-height: 1.25;
  color: #222;
}


.bc .type3 .list-area .l-content .l-cont{
  /* margin-top: 33px; */
  font-family: 'NanumSquareR';
  font-size: 18px;
  line-height: 2.11;
  color: #444;
}

.bc .content {
  margin-top: 90px;
  font-family: 'NanumSquareR';
  font-size: 18px;
  line-height: 1.67;
  color: #444;
  padding: 0 75px;
}

.bc .num {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 200px;
  font-family: 'Lato';
  font-size: 30px;
  color: #1d99df;
}

.bc .info {
  width: 100%;
  margin-top: 38px;
  font-family: 'NanumSquareR';
  font-size: 16px;
  line-height: 2.86;
  color: #d30000;
  text-align: right;
}


.bc .chart .cont {
  margin-left: 30px;
  font-family: 'NanumSquareR';
  font-size: 18px;
  line-height: 2.22;
  text-align: left;
  color: #444;
}


.bc .ref-area {
  margin-top: 100px;
  margin-bottom: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 61.458333;
  background-color: #f7f7f7;
  padding-left: 50px;
}

.bc .ref-area .tx {
  display: flex;
  flex-direction: column;
}

.bc .ref-area .tx .title {
  margin-top: 50px;
  font-family: 'ONE-Mobile-Regular';
  font-size: 48px;
  font-weight: bold;
  line-height: 1;
  color: #222;
}


.bc .ref-area .tx .sub-title {
  margin-top: 25px;
  margin-bottom: 50px;
  font-family: 'NanumSquareR';
  font-size: 18px;
  line-height: 1.67;
  color: #444;
}

.bc .ref-area .btn {
  margin-right: 50px;
  height: 30px;
  border-radius: 10px;
  background-color: #1d99df;
  align-items: center;
  font-family: 'NanumSquareR';
  font-size: 18px;
  line-height: 1.67;
  text-align: left;
  color: #fff;
  padding: 10px 30px;
}

.bc .ref-area .btn span{
  color: #e5e5e5;
}











@media screen and (min-width:769px) and (max-width:1180px) {
  .bc {
    width: 100%;
		margin-top: 0vw;
    margin-bottom: 0vw;
  }

  .bc .title {
    margin-top: 0;
  }
  
  .bc .content img{
    display: none;
  }

  .bc .type2 .title {
    margin-top: 10vw;
  }

  .bc .type3 .title {
    margin-top: 5.2083vw;
  }

}

















@media screen and (max-width:768px) {
  .bc {
    width: 100%;
		margin-top: 0vw;
    margin-bottom: 0vw;
  }

  .bc .content {
    padding: 0;
    font-size: 3.3854166vw;
  }

  .bc .type1{
    background-image: none;
    align-items: baseline;
  }

  .bc .type1 .title{
    margin-top: 0;
    font-size: 5.2083vw;
    font-weight: bold;
  }

  .bc .type1 .info {
    width: 100%;
    text-align: left;
    font-size: 2.86458vw;
    line-height: 1.82;
  }

  .bc .type1 .chart-area .doc{
    margin-right: 0;
  }

  .bc .type1 .chart-area .doc .rt {
    flex-direction: column;
    align-items: center;
    font-size: 3.90625vw;
    font-weight: bold;
    line-height: 1;
  }

  .bc .type1 .chart-area .doc .rt span{
    margin-top: 20px;
  }

  .bc .type1 .chart-area .doc .rt-3 {
    margin-top: 20px;
    max-width: 100%;
    align-items: center;
  }

  .bc .type1 .chart-area .doc .cont1 {
    max-width: 100%;
    font-size: 3.6458333vw;
  }

  .bc .type2 .title { 
    /* margin-top: 0; */
    font-size: 5.2083vw;
    font-weight: bold;
    line-height: 1.38;
    font-size: 5.2083vw;
  }

  .bc .type2 .sub-title { 
    line-height: 1.38;
    font-size: 3.3854166vw;
  }

  .bc .type2 .info {
    line-height: 1.82;
    font-size: 2.86458vw;
  }

  .bc .type2 .chart-area {
    margin-top: 3.90625vw;
    flex-direction: column;
  }

  .bc .type2 .chart-area .doc {
    margin-right: 0;
  }
  
  .bc .type2 .chart-area .doc .rt {
    flex-direction: column;
    align-items: center;
    font-size: 3.90625vw;
    font-weight: bold;
    line-height: 1;
  }

  .bc .type2 .chart-area .doc .rt span{
    margin-top: 20px;
  }

  .bc .type2 .chart-area .doc .rt-3 {
    margin-top: 20px;
    max-width: 100%;
    align-items: center;
  }

  .bc .type2 .chart-area .doc .cont {
    max-width: 100%;
    font-size: 3.6458333vw;
    line-height: 2.14;
  }

  .bc .type2 .chart-area .chart .rt-1 {
    font-size: 3.90625vw;
    line-height: 1;
  }

  .bc .type2 .chart-area .chart .rt-2 {
    font-size: 3.90625vw;
    line-height: 1;
  }

  .bc .type2 .chart .cont {
    font-size: 3.6458333vw;
  }

  .bc .type2 .chart-area .doc .cont1 {
    max-width: 100%;
    font-size: 3.6458333vw;
  }


  .bc .type2 .sub-info {
    font-size: 3.385416vw;
    margin-bottom: 2vw;
    line-height: 1.38;
  }

  .bc .type3 .title { 
    font-size: 5.2083vw;
    font-weight: bold;
    line-height: 1.38;
  }

  .bc .type3 .list-area .l-content .l-title {
    font-size: 5.2083vw;
  }

  .bc .type3 .list-area .l-content .l-title1 {
    margin: 0;
    font-size: 3.64583vw;
  }

  .bc .type3 .list-area .l-content .l-cont {
    font-size: 3.64583vw;
    line-height: 2.14;
  }

  .bc .num {
    display: none;
  }

  .bc .ref-area {
    margin-top: 13.0208vw;
    margin-bottom: 26.04166vw;
    align-items: baseline;
    padding-left: 6.5vw;
  }
  
  .bc .ref-area .tx {
    display: flex;
    flex-direction: column;
  }
  
  .bc .ref-area .tx .title {
    margin-top: 6.5104166vw;
    font-size: 5.2083vw;
    line-height: 1;
  }
  
  
  .bc .ref-area .tx .sub-title {
    font-size: 3.385416666vw;
    line-height: 1.38;
  }
  
  .bc .ref-area .btn {
    margin-right: 6.5vw;
    height: auto;
    border-radius: 1.30208vw;
    font-size: 2.564583vw;
    padding: 1.30208vw 3.90625vw;
  }

  .bc .ref-area .btn span{
    font-size: 1.564583vw;
  }
}