.nav {
  padding: 0px 50px;
  height: 97px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav .logo {
  width: 227px;
  height: 45px;
  margin: 30px 90px 22px 10px;
}

.nav .logo img { 
  scale: 1.2;
  /* width: 250px;
  height: 50px; */
}

.nav .nav-menu .nav-main-ul > li{
  display: inline-block;
  width: 220px;
  text-align: center;
}


.nav .nav-menu .nav-main-ul > li:not(:last-child){
  border-right: 1px solid #e5e5e5;
  
}

.nav .nav-menu .nav-main-ul > li > span{
  position: relative;
  font-family: 'NanumSquareAcr';
  font-size: 22px;
  letter-spacing: 1px;
  color: #002223;
}

/* font-family: 'NanumSquareLight';
font-family: 'NanumSquare';
font-family: 'NanumSquareBold';
font-family: 'NanumSquareExtraBold';
font-family: 'NanumSquareAcb';
font-family: 'NanumSquareAceb';
font-family: 'NanumSquareAcl';
font-family: 'NanumSquareAcr';
 */


 /* 서브 */
 .sub-nav {
   position: absolute;
   display: flex;
   flex-direction: row-reverse;
   z-index: 100;
   background-color: rgba(255, 255, 255, 0.98);
   /* background-color: #2c7eea; */
   width: 100%;
   box-shadow: 0 4px 2px -2px rgba(234, 237, 255, 0.86);
}

 .sub-nav  .sub-menu{
  padding: 0px 50px;
}

.sub-nav .sub-menu .sub-main-ul{
  color: white;
  display: flex;
}

.sub-nav .sub-menu .sub-main-ul > li{
  color: white;
  display: inline-block;
  width: 220px;
  text-align: center;
}

.sub-nav .sub-menu .sub-main-ul > li:not(:last-child){
  border-right: 1px solid rgba(0, 0, 0, 0);
}

.sub-nav .sub-menu .sub-main-ul li ul li{
  margin-bottom: 15px;
}

.sub-nav .sub-menu .sub-main-ul li ul li:last-child{
  margin-bottom: 50px;
}

.sub-nav .sub-menu .sub-main-ul li ul li a{

  font-family: 'NanumSquareAcr';
  font-size: 18px;
  letter-spacing: 1px;
  color: #002223;
  transition: color 0.2s, border-bottom 2s;
  position: relative;
}

.sub-nav .sub-menu .sub-main-ul li ul li a::after{
  content: "";
  position: absolute;
  background-color: #2c7eea;
  height: 1px;
  width: 0;
  left: 0;
  bottom: -5px;
  transition: 0.3s;
}

.sub-nav .sub-menu .sub-main-ul li ul li a:hover{
  color: #2c7eea;
}

.sub-nav .sub-menu .sub-main-ul li ul li a:hover::after{
  width: 100%;
}

.nav .hbg {
  display: none;
}

.mobile-nav-bg{
  display: none;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 300;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  overflow-y: hidden;
}

.mobile-nav-menu{
  display: block;
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 400;
  width: 0%;
  height: 100%;
  background-color: #fff;
  transition: 0.2s;
}

.mobile-nav-menu > ul > li:first-child{
  text-align: right;
  padding: 20px 27px 0px 0px;
}
.mobile-nav-menu > ul > li:first-child > img{
  cursor: pointer;
  width: 20px;
  background-color: #fff;
  text-align: right;
}

.mobile-nav-menu > ul > li{
  padding: 20px;
  font-size: 20px;
  font-weight: 00;
}

.mobile-nav-menu > ul > li > .sub-list-title{
  display: flex;
  justify-content: space-between;
  flex-direction: flex-end;
  cursor: pointer;
  
}

.mobile-nav-menu > ul > li > .sub-list-title .spreadBtn{
  font-weight: bolder;
  padding: 0px 10px;
  cursor: pointer;
}

.mobile-nav-menu > ul > li > .sub-list-title p,a{
  font-size: 20px;
}


.mobile-nav-menu > ul > li > .mobile-sub-list {
  margin-top: 20px;
  
}
.mobile-nav-menu > ul > li > .mobile-sub-list li{
  padding: 5px 20px;
}
.mobile-nav-menu > ul > li > .mobile-sub-list li a{
  font-size: 16px;
  font-family: 'NanumSquareAcr';
}










@media screen and (max-width:1280px) {
  .nav {
    padding: 3vw 3.906vw 3vw 3.906vw;
    height: 40px;
  }

  /* .nav .logo {
    width: 29.557vw;
    height: 5.859vw;
    margin: 0vw;
  } */

  .nav .logo {
    width: 29.557vw; 
    /* calc( 227px * 0.768 ); */
    height: 5.859375vw;
    /* calc( 45px * 0.768 ); */
    margin: 0px;
  }

  .nav .logo img{
    scale: 1;
    width: 100%;
    height: 100%;
  }

  .nav .nav-menu {
    display: none;
  }

  .nav .hbg {
    display: block;
  }
  
  .nav .hbg img{
    display: block;
  }


  .nav .hbg .hbg_btn{
    cursor: pointer;
  }

  .sub-nav {
    display: none;
  }
  
}


