.business {
  width: 100%;
  margin: 150px 0px 0px 0px;
}

.business .content {
  width:100%;
  display: flex;
}

.business .content .bg img {
  width: 576px;
  height: 356px;
  margin: 25px 0px 0px 0px;
  object-fit: contain;
}

.business .content .txArea {
  width:100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 98px;
}

.business .content .txArea .tb1 {
  margin: 0px 0px 0px 0px;
}

.business .content .txArea .tb1 span{
  font-family: 'ONE-Mobile-Title';
  font-size: 18px;
  line-height: 1.56;
  text-align: left;
  color: #2c7eea;
}

.business .content .txArea .tb2 {
  width: 68%;
  margin: 18px 0px 0px 0px;
}

.business .content .txArea .tb2 span{
  /* margin: 20px 58px 58px 59px; */
  font-family: 'ONE-Mobile-Title';
  font-size: 36px;
  line-height: 0.78;
  text-align: left;
  color: #222;
}

.business .content .txArea .tb2 span.bl {
  color: #2c7eea;
}

.business .content .txArea .tb3 {
  display: flex;
  width: 93%;
  margin: 51px 0px 0px 0px;
}

.business .content .txArea .tb3 span{
  font-family: 'NanumSquareAcr';
  font-size: 18px;
  line-height: 1.94;
  text-align: left;
  color: #444;
}

.business .content .txArea .next{
  display: flex;
  align-items: center;
  margin: 34px 0px 0px 0px;
}

.business .content .txArea .next img{
  margin: 0;
}

.business .content .txArea .next img:hover{
  transform: scale(1.05);
  cursor: pointer;
}

.business .content .txArea .next .rt{
  width: 1px;
  height: 15px;
  margin: 0px 14px;

  background-color: #e5e5e5;
}

.business .content .txArea .next .link{
  margin: 0px 40px 0px 0px;
}

.business .content .txArea .next .link img{
  width: 59px;
  height: 59px;
  margin: 0px 00px 0px 0px;
}

.business .btnArea {
  margin-top: 51px;
  max-width:1180px;
}

.business .btnArea .btnList {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.business .btnArea .btnList li{
  width: 271px;
  height: 271px;
  display: inline-block;
  margin: 9.5px 0px;
  border-radius: 20px;
  border: solid 1px #20BAEE;
}

.business .btnArea .btnList li .btn{
  width: 100%;
  height: 100%;
}

.business .btnArea .btnList li .btn .btnCont{
  width: 100%;
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-end;;
  border-radius: 20px;
  background-color: #f3f5fe;
}

.business .btnArea .btnList li .btn:hover .btnCont{
  display: none;
}

.business .btnArea .btnList li .btnCont .ic{
  margin-top: 70px;
  max-height: 50px;
  display:flex;
  justify-content: center;
}

.business .btnArea .btnList li .btnCont .tx1 {
  font-family: 'NanumSquareAcr';
  margin: 39px 0px 13px 0px;
  font-size: 16px;
  text-align: center;
  letter-spacing: normal;
  color: #222;
}

.business .btnArea .btnList li .btnCont .tx2 {
  margin: 0px 0px 71px 0px;
  font-family: 'NanumSquareAcr';
  font-size: 18px;
  text-align: center;
  color: #222;
}

.business .btnArea .btnList li .btn .hoverCont{
  display: none;
  font-size: 16px;
  width: 100%;
}

.business .btnArea .btnList li .btn .hoverCont li{
  width: 100%;
  height: 100%;
  border: none;
  margin: 6px 0px;
}

.business .btnArea .btnList li .btn .hoverCont .tx1{
  font-family: 'NanumSquareAcr';
  font-size: 18px;
  text-align: center;
  color: #222;
  margin: 20px 0px;
}

.business .btnArea .btnList li .btn .hoverCont .tx2{
  margin: 0px 0px 0px 15px;
  font-family: 'NanumSquareAcr';
  font-size: 18px;
  text-align: start;
  color: #222;
}

.business .btnArea .btnList li .btn .hoverCont .tx2:hover{
  color: #2c7eea;
}

.business .btnArea .btnList li .btn:hover .hoverCont{
  display: block;
  flex-direction: column;
  width: 100%;
}







@media screen and (min-width:769px) and (max-width:1180px) {
  .business {
    width: 100%;
		margin-top: 12.065vw;
    margin-bottom: 0vw;
  }
  
  .business .content img{
    display: none;
  }

  .business .content .txArea {
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 0px;
  }
  
  .business .content .txArea .tb1 {
    margin: 0px 0px 0px 0px;
  }
  
  .business .content .txArea .tb1 span{
    font-family: 'ONE-Mobile-Title';
    /* font-size: 3.385vw; */
    font-size: 1.6925vw;
    line-height: 1.08;
  }
  
  .business .content .txArea .tb2 {
    width: 100%;
    margin: 2.864vw 0vw 0vw 0vw;
  }
  
  .business .content .txArea .tb2 span{
    /* margin: 20px 58px 58px 59px; */
    font-family: 'ONE-Mobile-Title';
    /* font-size: 6.25vw; */
    font-size: 3.125vw;
    line-height: 0.58;
    text-align: left;
    color: #222;
  }
  
  .business .content .txArea .tb2 span.bl {
    color: #2c7eea;
  }
  
  .business .content .txArea .tb3 {
    display: flex;
    width: 100%;
    /* margin: 6.77vw 0vw 0vw 0vw; */
    margin: 3.385vw 0vw 0vw 0vw;
  }
  
  .business .content .txArea .tb3 span{
    font-family: 'NanumSquareAcr';
    font-size: 1.6925vw;
    line-height: 1.94;
    text-align: left;
    color: #444;
  }
  
  .business .content .txArea .next{
    display: flex;
    align-items: center;
    margin: 34px 0px 0px 0px;
  }
  
  .business .content .txArea .next img{
    display: block;
    margin: 0;
  }
  
  .business .content .txArea .next img:hover{
    transform: scale(1.05);
    cursor: pointer;
  }
  
  .business .content .txArea .next .rt{
    width: 1px;
    height: 15px;
    margin: 0px 14px;
  
    background-color: #e5e5e5;
  }
  
  .business .content .txArea .next .link{
    margin: 0px 40px 0px 0px;
  }
  
  .business .content .txArea .next .link img{
    width: 59px;
    height: 59px;
    margin: 0px 00px 0px 0px;
  }

  .business .btnArea .btnList {
  }
  

  .business .btnArea .btnList li{
    width: 22vw;
    height: 22vw;
    margin: 1.236vw 0vw;
    display:inline-block;
  }
  
  .business .btnArea .btnList li .btnCont{
    width: 100%;
    height: 100%;
    align-items: center;
    border-radius: 1.302vw;
  }

  .business .btnArea .btnList li .btnCont:hover{
    transform: scale(1);
    background-color: #e7ebff;
  }
  
  
  .business .btnArea .btnList li .btnCont .ic{
    max-width: 6.51vw;
    max-height: 6.184vw;
    width: auto;
    height: auto;
    margin-top: 3.080vw;
    display:flex;
    justify-content: center;
  }

  .business .btnArea .btnList li .btnCont .ic img{
    width: 100%;
    height: 100%;
  }
  
  .business .btnArea .btnList li .btnCont .tx1 {
    font-family: 'NanumSquareAcr';
    margin: 3.10vw 0vw 1.888vw 0vw;
    font-size: 1.692vw;
    text-align: center;
    letter-spacing: normal;
    color: #222;
  }
  
  .business .btnArea .btnList li .btnCont .tx2 {
    margin: 0vw 0vw 3.71vw 0vw;
    font-family: 'NanumSquareAcr';
    font-size: 2.213vw;
    text-align: center;
    color: #222;
  }
  

}









@media screen and (max-width:768px) {
  .business {
    width: 100%;
		margin-top: 25.13vw;
    margin-bottom: 26.041vw;
  }
  
  .business .content img{
    display: none;
  }

  .business .content .txArea {
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 0px;
  }
  
  .business .content .txArea .tb1 {
    margin: 0px 0px 0px 0px;
  }
  
  .business .content .txArea .tb1 span{
    font-family: 'ONE-Mobile-Title';
    font-size: 3.385vw;
    line-height: 1.08;
  }
  
  .business .content .txArea .tb2 {
    width: 100%;
    margin: 2.864vw 0vw 0vw 0vw;
  }
  
  .business .content .txArea .tb2 span{
    /* margin: 20px 58px 58px 59px; */
    font-family: 'ONE-Mobile-Title';
    font-size: 6.25vw;
    line-height: 0.58;
    text-align: left;
    color: #222;
  }
  
  .business .content .txArea .tb2 span.bl {
    color: #2c7eea;
  }
  
  .business .content .txArea .tb3 {
    display: flex;
    width: 100%;
    margin: 6.77vw 0vw 0vw 0vw;
  }
  
  .business .content .txArea .tb3 span{
    font-family: 'NanumSquareAcr';
    font-size: 3.385vw;
    line-height: 1.94;
    text-align: left;
    color: #444;
  }
  
  .business .content .txArea .next{
    display: flex;
    align-items: center;
    margin: 34px 0px 0px 0px;
  }
  
  .business .content .txArea .next img{
    display: block;
    margin: 0;
  }
  
  .business .content .txArea .next img:hover{
    transform: scale(1.05);
    cursor: pointer;
  }
  
  .business .content .txArea .next .rt{
    width: 1px;
    height: 15px;
    margin: 0px 14px;
  
    background-color: #e5e5e5;
  }
  
  .business .content .txArea .next .link{
    margin: 0px 40px 0px 0px;
  }
  
  .business .content .txArea .next .link img{
    width: 59px;
    height: 59px;
    margin: 0px 00px 0px 0px;
  }

  .business .btnArea .btnList {
  }
  

  .business .btnArea .btnList li{
    width: 43.921vw;
    height: 43.921vw;
    margin: 1.236vw 0vw;
    display:inline-block;
  }
  
  .business .btnArea .btnList li .btn .btnCont{
    display: none;
  }

  .business .btnArea .btnList li .btn .hoverCont{
    display: block;
  }  

  .business .btnArea .btnList li .btn .hoverCont .tx1{
    font-size: 4vw;
    margin: 10px 0px;
  }
  
  .business .btnArea .btnList li .btn .hoverCont .tx2{
    font-size: 3vw;
    margin: 0px 0px 0px 10px;
  }
  
  .business .btnArea .btnList li .btn .hoverCont li{
    margin: 0px 0px;
  }
  
  /* .business .btnArea .btnList li .btnCont{
    width: 100%;
    height: 100%;
    align-items: center;
    border-radius: 2.604vw;
  }

  .business .btnArea .btnList li .btnCont:hover{
    transform: scale(1);
    background-color: #e7ebff;
  }
  
  
  .business .btnArea .btnList li .btnCont .ic{
    max-width: 13.02vw;
    max-height: 12.369vw;
    width: auto;
    height: auto;
    margin-top: 7.161vw;
    display:flex;
    justify-content: center;
  }

  .business .btnArea .btnList li .btnCont .ic img{
    width: 100%;
    height: 100%;
  }
  
  .business .btnArea .btnList li .btnCont .tx1 {
    font-family: 'NanumSquareAcr';
    margin: 7.42vw 0vw 3.776vw 0vw;
    font-size: 3.385vw;
    text-align: center;
    letter-spacing: normal;
    color: #222;
  }
  
  .business .btnArea .btnList li .btnCont .tx2 {
    margin: 0vw 0vw 7.42vw 0vw;
    font-family: 'NanumSquareAcr';
    font-size: 4.427vw;
    text-align: center;
    color: #222;
  } */
  

}