.login {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}

.login .input-area {
  display: flex;
  flex-direction: column;
  justify-items: center;
  max-width: 400px;
  width: 100%;
  margin-top: 150px;
} 

.login .input-area .input-unit {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login .input-area .input-unit .input-hook{
  width: calc(100% - 25px);
  height: 62px;
  padding: 0px 0px 0px 25px;
}

.login .input-area .input-unit input{
  display: block;
  /* font-family: 'NanumSquareR'; */
  font-size: 16px;
  margin: 20px 0px;
  border-radius: 10px;
  border: solid 1px #e5e5e5;
}

.login .input-area .input-unit .error-message{
  color: red;
  font-size: 12px;
  text-align: left;
}

.login .input-area .input-unit input::placeholder {
  color: #999;
  font-style: 'NanumSquareR';
}

.login .input-area .board-btn-area {
  display: flex;
  justify-content: center;
  margin: 50px 0px 210px 0px;
}

.login .input-area .board-btn-area .login-btn {
  cursor: pointer;
  width: 100vw;
  height: 69px;
  font-size: 24px;
  color: #fff;
  margin: 0px 0px 0px 0px;
  border-radius: 10px;
  background-color: #1d99df;
}


@media screen and (max-width:768px) {

  .login .input-area {
    justify-content: center;
    margin-top: 13.0208vw;
  }

  .login .input-area .input-unit .input-hook{
    /* width: 78.125vw; */
    margin: 20px 0px;
  }

}