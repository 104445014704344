.bgImg{
  background-image: url("../../images/injunglaw/greeting01.png");
  background-repeat: no-repeat;
  background-position: center;
}

.greetings {
  width: 100%;
  margin: -695px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}

.greetings .content {
  width: 100%;
}

.greetings .content .txArea{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}

.greetings .content .txArea .tb1 {
  width: 100%;  
  text-align: right;
}

.greetings .content .txArea .tb1 span{
  font-family: 'Marcellus';
  font-size: 130px;
  line-height: 0.26;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #222;
}

.greetings .content .txArea .tb2 {
  width: 100%;
  margin: 52px 0px 0px 0px;
  text-align: right;
}

.greetings .content .txArea .tb2 span{
  /* margin: 20px 58px 58px 59px; */
  font-family: 'ONE-Mobile-Regular';
  font-size: 36px;
  line-height: 1.39;
  color: #222;
}

.greetings .content .txAreaBt{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}

.greetings .content .txAreaBt .tb1 {
  margin: 673px 0px 0px 0px;
  width: 100%;  
}

.greetings .content .txAreaBt .tb1-m {
  display: none;
  margin: 673px 0px 0px 0px;
  width: 100%;  
}


.greetings .content .txAreaBt .tb1 span{
  font-family: 'ONE-Mobile-Regular';
  font-size: 36px;
  line-height: 1.39;
  color: #222;
}

.greetings .content .txAreaBt .tb2 {
  width: 100%;
  margin: 56px 0px 0px 0px;
}

.greetings .content .txAreaBt .tb2-m {
  display: none;
  width: 100%;
  margin: 56px 0px 0px 0px;
}

.greetings .content .txAreaBt .tb2 span{
  font-family: 'NanumSquareAcr';
  font-size: 18px;
  line-height: 1.56;
  color: #222;
}

.greetings .content .txAreaBt .tb3 {
  width: 100%;
  margin: 185px 0px 0px 0px;
}

.greetings .content .txAreaBt .tb3 span{
  font-family: 'Marcellus';
  font-size: 130px;
  line-height: 1.15;
  color: rgba(229, 229, 229, 0.67);
}

.greetings .content .txAreaBt .bg {
  margin: -592px 0px 200px 0px;
  z-index: -1;
}















@media screen and (min-width:769px) and (max-width:1180px) {
  .bgImg img{
    width: 100vw;
    height: 87.239vw;
    object-fit:cover;
  }
  
  .greetings {
    width: 100%;
    margin: -695px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
  }
  
  .greetings .content {
    width: 100%;
  }
  
  .greetings .content .txArea{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
  }
  
  .greetings .content .txArea .tb1 {
    width: 100%;  
    text-align: right;
  }
  
  .greetings .content .txArea .tb1 span{
    font-family: 'Marcellus';
    font-size: 130px;
    line-height: 0.26;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #222;
  }
  
  .greetings .content .txArea .tb2 {
    width: 100%;
    margin: 52px 0px 0px 0px;
    text-align: right;
  }
  
  .greetings .content .txArea .tb2 span{
    /* margin: 20px 58px 58px 59px; */
    font-family: 'ONE-Mobile-Regular';
    font-size: 36px;
    line-height: 1.39;
    color: #222;
  }
  
  .greetings .content .txAreaBt{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
  }
  
  .greetings .content .txAreaBt .tb1 {
    margin: 673px 0px 0px 0px;
    width: 100%;  
  }
  
  .greetings .content .txAreaBt .tb1 span{
    font-family: 'ONE-Mobile-Regular';
    font-size: 36px;
    line-height: 1.39;
    color: #222;
  }
  
  .greetings .content .txAreaBt .tb2 {
    width: 100%;
    margin: 56px 0px 0px 0px;
  }
  
  .greetings .content .txAreaBt .tb2 span{
    font-family: 'NanumSquareAcr';
    font-size: 18px;
    line-height: 1.56;
    color: #222;
  }
  
  .greetings .content .txAreaBt .tb3 {
    width: 100%;
    margin: 185px 0px 0px 0px;
  }
  
  .greetings .content .txAreaBt .tb3 span{
    font-family: 'Marcellus';
    font-size: 130px;
    line-height: 1.15;
    color: rgba(229, 229, 229, 0.67);
  }
  
  .greetings .content .txAreaBt .bg {
    margin: -592px 0px 200px 0px;
  }
  

}
















@media screen and (max-width:768px) {
  .bgImg img{
    width: 100vw;
    height: 87.239vw;
    object-fit:cover;
  }
  
  .greetings {
    width: 100%;
    margin: -90.494vw 0vw 0vw 0vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
  }
  
  .greetings .content {
    width: 100%;
  }
  
  .greetings .content .txArea{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
  }
  
  .greetings .content .txArea .tb1 {
    width: 100%;
    text-align: right;
  }
  
  .greetings .content .txArea .tb1 span{
    font-size: 13.02vw;
    line-height: 0.34;
  }
  
  .greetings .content .txArea .tb2 {
    width: 100%;
    margin: 5.729vw 0vw 0vw 0vw;
    text-align: right;
  }
  
  .greetings .content .txArea .tb2 span{
    /* margin: 20px 58px 58px 59px; */
    font-family: 'ONE-Mobile-Regular';
    font-size: 4.6875vw;
    line-height: 1.39;
    color: #222;
  }
  
  .greetings .content .txAreaBt{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    margin-bottom: 25.26vw;
  }
  
  .greetings .content .txAreaBt .tb1 {
    display: none;
  }

  .greetings .content .txAreaBt .tb1-m {
    display: inline-block;
    width: 100%;
    margin: 77.213vw 0vw 0vw 0vw;
  }
  
  .greetings .content .txAreaBt .tb1-m span{
    font-size: 5.208vw;
    line-height: 1.38;
  }
  
  .greetings .content .txAreaBt .tb2 {
    display: none;
  }

  .greetings .content .txAreaBt .tb2-m {
    display: inline-block;
    width: 100%;
    margin: 6.38vw 0vw 0vw 0vw;
  }
  
  .greetings .content .txAreaBt .tb2-m span{
    font-family: 'NanumSquareAcr';
    font-size: 2.343vw;
    line-height: 1.56;
    color: #222;
  }
  
  .greetings .content .txAreaBt .tb3 {
    width: 100%;
    margin: 84.375vw 0vw 0vw 0vw;
  }
  
  .greetings .content .txAreaBt .tb3 span{
    font-family: 'Marcellus';
    font-size: 10.416vw;
    line-height: 1;
    color: rgba(229, 229, 229, 0.67);
  }
  
  .greetings .content .txAreaBt .bg {
    margin: -90.494vw 0vw 0vw 0vw;
  }

  .greetings .content .txAreaBt .bg img{
    width: 66.145vw;
    height: 80.729vw;
  }

  

}