.direction {
  width: 100%;
  margin: 0px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}

.direction .content {
  width: 100%;
}

.direction .content .txArea{
  width: 100%;
  display: flex;
  flex-direction: column;
}

.direction .content .txArea .tb1 {
  text-align: left;
}

.direction .content .txArea .tb1 span{
  font-family: 'NanumSquareR';
  font-size: 16px;
  line-height: 1.88;
  color: #222;
}

.direction .content .txArea .tb2 {
  margin: -5px 0px 0px 77px;
  text-align: left;
  z-index: -1;
}

.direction .content .txArea .tb2 span{
  font-family: 'Montserrat';
  font-size: 72px;
  font-weight: bold;
  line-height: 0.42;
  color: #f7f7f7;
}

.direction .content .txArea .tb3 {
  margin: -15px 0px 0px 0px;
  text-align: left;
}

.direction .content .txArea .tb3 span{
  font-family: 'ONE-Mobile-Regular';
  font-size: 36px;
  font-weight: bold;
  line-height: 0.83;
  color: #222;
}

.direction .content .txAreaBt{
  margin-top: 47px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.direction .content .txAreaBt .tb4 {
  margin: 0px 11px 0px 0px;
}

.direction .content .txAreaBt .tb4 span{
  font-family: 'Lato';
  font-size: 16px;
  line-height: 1.88;
  color: #222;
}

.direction .content .txAreaBt .tb5 span{
  font-family: 'NanumSquareR';
  font-size: 16px;
  line-height: 1.88;
  color: #777;
}

.direction .content .txAreaBt2{
  margin-top: 31px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.direction .content .txAreaBt2 .cl {
  display: flex;
}

.direction .content .txAreaBt2 .tb6 {
  margin: 0px 20px 0px 0px;
}


.direction .content .txAreaBt2 .tb6 span{
  font-family: 'Lato';
  font-size: 16px;
  line-height: 1.88;
  color: #222;
}

.direction .content .txAreaBt2 .tb7 {
  margin: 0px 50px 0px 0px;
}

.direction .content .txAreaBt2 .tb7 span{
  font-family: 'Lato';
  font-size: 16px;
  line-height: 1.88;
  color: #777;
}

.direction .mapArea {
  margin-top: 50px;
  width: 61.4583vw;
  height: 32.65625vw;
  background-color: aqua;
}

.direction .info {
  margin-top: 34px;
  margin-bottom: 190px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.direction .info .subway {
  display: flex;
  flex-direction: column;
}

.direction .info .subway .content{
  margin-top: 1.09375vw;
  display: flex;
  align-items: center;
}

.direction .info .subway .content .title{
  margin-left: 11px;
  display: flex;
  align-items: center;
}

.direction .info .subway .content .title span{
  font-family: 'NanumSquareR';
  font-size: 20px;
  line-height: 1.5;
  text-align: left;
  color: #222;
}

.direction .info .subway .content .tb8{
  margin: 0 0 0 1.40625vw;
}

.direction .info .subway .content .tb8 span{
  font-family: 'NanumSquareR';
  font-size: 16px;
  line-height: 1.88;
  text-align: left;
  color: #222;
}

.direction .info .subway .content .rt1{
  width: 81px;
  height: 30px;
  margin: 0px 3px 0px 0px;
  border-radius: 25px;
  border: solid 2px #109c17;
  background-color: #fff;
  text-align: center;
}

.direction .info .subway .content .rt1 span{
  font-family: 'NanumSquareBold';
  font-size: 14px;
  line-height: 2.14;
  color: #4cb551;
}

.direction .info .subway .content .rt2{
  width: 100px;
  height: 30px;
  margin-left: 2px;
  border-radius: 25px;
  border: solid 2px #eb1753;
  background-color: #fff;
  text-align: center;
}

.direction .info .subway .content .rt2 span{
  font-family: 'NanumSquareBold';
  font-size: 14px;
  line-height: 2.14;
  color: #eb1753;
}

.direction .info .subway .content .rt3{
  width: 81px;
  height: 30px;
  border-radius: 25px;
  border: solid 2px #cda52e;
  background-color: #fff;
  text-align: center;
}

.direction .info .subway .content .rt3 span{
  font-family: 'NanumSquareBold';
  font-size: 14px;
  line-height: 2.14;
  color: #cda52e;
}

.direction .info .subway .content .rt4{
  width: 104px;
  height: 30px;
  margin-left: 2px;
}

.direction .info .bus {
  display: flex;
  flex-direction: column;
}

.direction .info .bus .content{
  margin-top: 1.09375vw;
  display: flex;
  align-items: center;
}

.direction .info .bus .content .title{
  margin-left: 11px;
  display: flex;
  align-items: center;
}

.direction .info .bus .content .title span{
  font-family: 'NanumSquareR';
  font-size: 20px;
  line-height: 1.5;
  text-align: left;
  color: #222;
}

.direction .info .bus .content .desc{
  width: 100%;
  margin-bottom: 1.552vw;
}

.direction .info .bus .content .desc span{
  font-family: 'NanumSquareR';
  font-size: 16px;
  line-height: 1.88;
  text-align: left;
  color: #777;
}

.direction .info .bus .content .tb10{
  width: 70px;
  font-family: 'NanumSquareR';
  font-size: 16px;
  line-height: 30px;
  text-align: left;
  color: #213886;
}

.direction .info .bus .content .tb11{
  width: 70px;
  font-family: 'NanumSquareR';
  font-size: 16px;
  line-height: 30px;
  text-align: left;
  color: #109c17;
}

.direction .info .bus .content .tb12{
  width: 70px;
  font-family: 'NanumSquareR';
  font-size: 16px;
  line-height: 30px;
  text-align: left;
  color: #cf1c1c;
}

.direction .info .bus .content .tb13{
  width: 70px;
  font-family: 'NanumSquareR';
  font-size: 16px;
  line-height: 30px;
  text-align: left;
  color: #201d1d;
}

.direction .info .bus .content .tb14{
  margin-left: 1.09375vw;
  font-family: 'Lato';
  font-size: 16px;
  line-height: 1.88;
  text-align: left;
  color: #201d1d;
}

.direction .info .car {
  display: flex;
  flex-direction: column;
}

.direction .info .car .content{
  margin-top: 1.09375vw;
  display: flex;
  align-items: center;
}

.direction .info .car .content .title{
  margin-left: 11px;
  font-family: 'NanumSquareBold';
  font-size: 20px;
  line-height: 1.5;
  text-align: left;
  color: #222;
}

.direction .info .car .content .tb15{
  font-family: 'NanumSquareR';
  font-size: 16px;
  line-height: 1.88;
  text-align: left;
  color: #222;
}




















@media screen and (min-width:769px) and (max-width:1180px) {
 
}




















@media screen and (max-width:768px) {
  .direction .content .txArea .tb1 span{
    font-size: 3.3854vw;
  }
  
  .direction .content .txArea .tb2 {
    margin: -2.2135vw 0px 0px 10.026vw;
  }
  
  .direction .content .txArea .tb2 span{
    font-size: 9.375vw;
  }
  
  .direction .content .txArea .tb3 {
    margin: -1.953125vw 0px 0px 0px;
  }
  
  .direction .content .txArea .tb3 span{
    font-size: 5.7291vw;
  }
  
  .direction .content .txAreaBt{
    margin-top: 5.859375vw;
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
  
  .direction .content .txAreaBt .tb4 {
    margin: 0px 1.432vw 4.557vw 0px;
  }
  
  .direction .content .txAreaBt .tb4 span{
    font-size: 3.38541vw;
  }
  
  .direction .content .txAreaBt .tb5 span{
    font-size: 3.38541vw;
  }
  
  .direction .content .txAreaBt2{
    margin-top: 0vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  
  .direction .content .txAreaBt2 .cl {
    display: flex;
  }
  
  .direction .content .txAreaBt2 .tb6 {
    margin: 0px 2.6041vw 0px 0px;
  }
  
  
  .direction .content .txAreaBt2 .tb6 span{
    font-size: 3.38541vw;
  }
  
  .direction .content .txAreaBt2 .tb7 {
    margin: 0px 6.5104vw 4.557vw 0px;
  }
  
  .direction .content .txAreaBt2 .tb7 span{
    font-size: 3.38541vw;
  }
  
  .direction .mapArea {
    margin-top: 6.5104;
    width: 92.4479vw;
    height: 81.640625vw;
    background-color: aqua;
  }
  
  .direction .info {
    margin-top: 6.51041vw;
    margin-bottom: 26.0416vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  
  .direction .info .subway .content{
    margin-top: 3.90625vw;
  }
  
  .direction .info .subway .content .title{
    margin-left: 2.083vw;
  }
  
  .direction .info .subway .content .title span{
    font-size: 4.42708vw;
    line-height: 0.88;
  }
  
  .direction .info .subway .content .tb8{
    margin: 0 0 0 5.2083vw;
  }
  
  .direction .info .subway .content .tb8 span{
    font-family: 'NanumSquareR';
    font-size: 3.3854vw;
    line-height: 1.88;
    text-align: left;
    color: #222;
  }
  
  .direction .info .subway .content .rt1{
    width: 10.546875vw;
    height: 3.90625vw;
    margin: 0px 0.390625vw 0px 0px;
    border-radius: 3.2552083vw;
    border: solid 0.2604vw #109c17;
    background-color: #fff;
    text-align: center;
  }
  
  .direction .info .subway .content .rt1 span{
    font-family: 'NanumSquareBold';
    font-size: 1.822916vw;
    line-height: 2.14;
    color: #4cb551;
  }
  
  .direction .info .subway .content .rt2{
    width: 17.0208vw;
    height: 3.90625vw;
    margin-left: 0.2604vw;
    border-radius: 3.2552083vw;
    border: solid 0.2604vw #eb1753;
    background-color: #fff;
    text-align: center;
  }
  
  .direction .info .subway .content .rt2 span{
    font-family: 'NanumSquareBold';
    font-size: 1.822916vw;
    line-height: 2.14;
    color: #eb1753;
  }
  
  .direction .info .subway .content .rt3{
    width: 10.546875vw;
    height: 3.90625vw;
    border-radius: 3.2552083vw;
    border: solid 0.2604vw #cda52e;
    background-color: #fff;
    text-align: center;
  }
  
  .direction .info .subway .content .rt3 span{
    font-size: 1.822916vw;
    line-height: 2.14;
  }
  
  .direction .info .subway .content .rt4{
    width: 13.4vw;
    height: 3.90625vw;
    margin-left: 0.2604vw;
  }
  
  .direction .info .bus {
    margin-top: 11.71875vw;
  }
  
  .direction .info .bus .content{
    margin-top: 3.90625vw;
  }

  .direction .info .bus .content .title{
    margin-left: 2.083vw;
  }
  
  .direction .info .bus .content .title span{
    font-size: 4.427vw;
    line-height: 0.88;
  }
  
  .direction .info .bus .content .desc{
    width: 70%;
    margin-bottom: 1.552vw;
  }
  
  .direction .info .bus .content .desc span{
    font-size: 3.3854vw;
    line-height: 1.38;
  }
  
  .direction .info .bus .content .tb10{
    width: 17vw;
    font-size: 3.25vw;
    line-height: 2.31;
  }
  
  .direction .info .bus .content .tb11{
    width: 17vw;
    font-size: 3.25vw;
    line-height: 2.31;
  }
  
  .direction .info .bus .content .tb12{
    width: 17vw;
    font-size: 3.25vw;
    line-height: 2.31;
  }
  
  .direction .info .bus .content .tb13{
    width: 17vw;
    font-size: 3.25vw;
    line-height: 2.31;
  }
  
  .direction .info .bus .content .tb14{
    margin-left: 1.09375vw;
    font-size: 3.25vw;
    line-height: 2.31;
  }
  
  .direction .info .car {
    margin-top: 11.71875vw;
  }
  
  .direction .info .car .content{
    margin-top: 1.09375vw;
  }
  
  .direction .info .car .content .title{
    margin-left: 2.083vw;
    font-size: 4.427vw;
    line-height: 0.88;
  }
  
  .direction .info .car .content .tb15{
    margin-top: 3.90625vw;
    font-size: 3.3vw;
    line-height: 1.77;
  }

  

}