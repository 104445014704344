.slick-dots
{
    position: absolute;
    bottom: 25px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}

.slick-arrow{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.slick-arrow.slick-prev{
  top: 130%;
  left: 45%;
}

.slick-arrow.slick-next{
  top: 130%;
  left: 55%;
}

.slick-prev::before,
.slick-next::before {
  opacity: 0;
  display: none;
}

.slick-arrow img {
  width: 20px;
  height: 16px;
}

.visual {
  height: 780px;
}

.visual .slide-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 190px;
}

.visual img{
  width: 1920px;
  height: 780px;
  position: absolute;
  z-index: -2;
  object-fit: fill;
}

.visual .content {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.visual .content .innerBg {
  display: none;
}

.visual .content .tb1 {
  margin: 68px 0px 0px 0px;
}

.visual .content .tb1 span{
  margin: 0px 0px 0px 0px;
  font-family: 'NanumSquareAcr';
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.08;
  letter-spacing: normal;
  text-align: left;
  color: #222;
}

.visual .content .tb2 {
  width: 575px;
  margin: 18px 0px 0px 0px;
}

.visual .content .tb2 span{
  font-family: 'ONE-Mobile-Title';
  font-size: 48px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #222;
}

.visual .content .tb2 span.bl {
  color: #2c7eea;
}

.visual .content .tb3 {
  display: flex;
  width: 100%;
  margin: 51px 0px 0px 7px;
}

.visual .content .tb3 .rt{
  width: 7px;
  height: 7px;
  margin: 10px 0px 0px 0px;
  background-color: #222;
}

.visual .content .tb3 .sp{
  margin: 0px 0px 0px 11px;
  font-family: 'NanumSquareAcr';
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #222;
}

.visual .bg {
  width: 45.1%;
}

.visual .bg img{
  width: 100%;
}

.visual .cont {
  width: 100%;
  margin: 51px 0px 0px 7px;
}

.visual .cont .btnList {
	width: 70%;
  display: flex;
  justify-content: space-evenly;
}

.visual .cont .btnList li{
  border-radius: 20px;
  background-color: #fff;  
  box-shadow: 3.4px 6.1px 21px 0 rgba(234, 237, 255, 0.86);
}

.visual .cont .btnList li .btnCont{
  width: 139px;
  height: 139px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.visual .cont .btnList li:hover{
  box-shadow: 3.4px 6.1px 21px 0 rgba(181, 184, 199, 0.86);
}


.visual .cont .btnList li .btnCont .ic{
  /* display:flex;
  justify-content: center; */
}

.visual .cont .btnList li .btnCont .tx {
  font-family: 'NanumSquareAcr';
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  /* line-height: 3.75; */
  letter-spacing: normal;
  color: #222;
}






@media screen and (min-width:769px) and (max-width:1180px) {
  .visual .content {
    width: 100%;
    margin: 0px 0em;
  }
  
  .visual .content .innerBg {
    width: 100%;
    display: inline-block;
    position: relative;
  }

  .visual .content .innerBg img {
    width: 100%;
  }
  
  .visual .content .tb1 {
    margin: 8.854vw 0px 0px 4.427vw;
  }
  
  .visual .content .tb1 span{
    margin: 0px 0px 0px 0px;
    font-family: 'NanumSquareAcr';
    font-size: 3.9vw;
    line-height: 1.08;
    text-align: left;
    color: #222;
  }
  
  .visual .content .tb2 {
    width: 80%;
    margin: 2.474vw 0px 0px 3.77vw;
  }
  
  .visual .content .tb2 span{
    font-family: 'ONE-Mobile-Title';
    font-size: 6.25vw;
    line-height: 1.25;
    text-align: left;
    color: #222;
  }
  
  .visual .content .tb2 span.bl {
    color: #2c7eea;
  }
  
  .visual .content .tb3 {
    display: flex;
    width: 90%;
    margin: 5.468vw 0 7.03vw 3.77vw
  }
  
  .visual .content .tb3 .rt{
    width: 0.911vw;
    height: 0.911vw;
    margin: 1.4vw 0 0 0;
    background-color: #777;
  }
  
  .visual .content .tb3 .sp{
    margin: 0vw 0vw 0vw 1.82vw;
    font-family: 'NanumSquareAcr';
    font-size: 3.385vw;
    line-height: 1.54;
    text-align: left;
    color: #444;
  }
  
  .visual .bg {
    display: none;
  }
  
  .visual .cont {
    width: 100%;
    margin: 10.416vw 0vw 0vw 0vw;
  }
  
  .visual .cont .btnList {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  
  .visual .cont .btnList li{
    width: 22.13vw;
    border-radius: 20px;
    background-color: #fff;  
    box-shadow: 3.4px 6.1px 21px 0 rgba(234, 237, 255, 0.86);
  }
  
  .visual .cont .btnList li .btnCont{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  
  .visual .cont .btnList li:hover{
    box-shadow: 3.4px 6.1px 21px 0 rgba(181, 184, 199, 0.86);
  }
  
  .visual .cont .btnList li .btnCont .ic{
    margin: 3.9vw 0 4.94vw 0;
  }
  
  .visual .cont .btnList li .btnCont .ic img{
    width: 5.599vw;
    height: 5.599vw;
  }
  
  .visual .cont .btnList li .btnCont .tx {
    font-family: 'NanumSquareAcr';
    font-size: 3.385vw;
    margin: 0 0 4.29vw 0;
    /* line-height: 3.75; */
    color: #222;
  }
}









@media screen and (max-width:768px) {
  .visual {
    height: 360px;
  }

  .visual .slide-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
  }

  .visual .content {
    width: 100%;
    margin: 0px 0em;
  }
  
  .visual .content .innerBg {
    width: 100%;
    display: inline-block;
    position: relative;
  }

  .visual .content .innerBg img {
    width: 100%;
  }
  
  .visual .content .tb1 {
    margin: 8.854vw 0px 0px 4.427vw;
  }
  
  .visual .content .tb1 span{
    margin: 0px 0px 0px 0px;
    font-family: 'NanumSquareAcr';
    font-size: 3.9vw;
    line-height: 1.08;
    text-align: left;
    color: #222;
  }
  
  .visual .content .tb2 {
    width: 80%;
    margin: 2.474vw 0px 0px 3.77vw;
  }
  
  .visual .content .tb2 span{
    font-family: 'ONE-Mobile-Title';
    font-size: 6.25vw;
    line-height: 1.25;
    text-align: left;
    color: #222;
  }
  
  .visual .content .tb2 span.bl {
    color: #2c7eea;
  }
  
  .visual .content .tb3 {
    display: flex;
    width: 90%;
    margin: 5.468vw 0 7.03vw 3.77vw
  }
  
  .visual .content .tb3 .rt{
    width: 0.911vw;
    height: 0.911vw;
    margin: 1.4vw 0 0 0;
    background-color: #777;
  }
  
  .visual .content .tb3 .sp{
    margin: 0vw 0vw 0vw 1.82vw;
    font-family: 'NanumSquareAcr';
    font-size: 3.385vw;
    line-height: 1.54;
    text-align: left;
    color: #444;
  }
  
  .visual .bg {
    display: none;
  }
  
  .visual .cont {
    width: 100%;
    margin: 10.416vw 0vw 0vw 0vw;
  }
  
  .visual .cont .btnList {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  
  .visual .cont .btnList li{
    width: 22.13vw;
    border-radius: 20px;
    background-color: #fff;  
    box-shadow: 3.4px 6.1px 21px 0 rgba(234, 237, 255, 0.86);
  }
  
  .visual .cont .btnList li .btnCont{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  
  .visual .cont .btnList li:hover{
    box-shadow: 3.4px 6.1px 21px 0 rgba(181, 184, 199, 0.86);
  }
  
  .visual .cont .btnList li .btnCont .ic{
    margin: 3.9vw 0 4.94vw 0;
  }
  
  .visual .cont .btnList li .btnCont .ic img{
    width: 5.599vw;
    height: 5.599vw;
  }
  
  .visual .cont .btnList li .btnCont .tx {
    font-family: 'NanumSquareAcr';
    font-size: 3.385vw;
    margin: 0 0 4.29vw 0;
    /* line-height: 3.75; */
    color: #222;
  }
}

/* font-family: 'NanumSquareLight';
font-family: 'NanumSquare';
font-family: 'NanumSquareBold';
font-family: 'NanumSquareExtraBold';
font-family: 'NanumSquareAcb';
font-family: 'NanumSquareAceb';
font-family: 'NanumSquareAcl';
font-family: 'NanumSquareAcr';
 */



